*,
*::before,
*::after {
  box-sizing: border-box;
}

.bg-themedark {
  background-color: #125d60;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: "Orbitron", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  text-align: left;
  background-color: #000000;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding-bottom: env(safe-area-inset-bottom);
  overflow-x: hidden;
  word-wrap: break-word;
  filter: hue-rotate(33deg);
}
body::before {
  content: "";
  background-color: #000000;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  background-image: url(../images/bgAnim.gif);
  opacity: 0;
  z-index: -1;
  transition: all 1.2s ease-in-out;
}

body:not(.pageLoaded) {
  height: 100vh;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.bg-themedark {
  background-color: #125d60;
}

.bg-themelight {
  background-color: #01ffff;
}

.oc_row {
  display: flex;
  flex-wrap: wrap;
}

.oc_col6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 10px;
}

.oc_container {
  width: 100%;
  max-width: calc(100% - 80px);
  margin-left: auto;
  margin-right: auto;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.cirLeftToRight {
  transform-origin: center;
  animation: keyLeftToRight 5s ease-in-out infinite alternate-reverse;
}

.cirLeftToRightR {
  transform-origin: center;
  animation: keyLeftToRight 10s ease-in-out infinite alternate;
}

.cirLeftToRightRS {
  transform-origin: center;
  animation: keyLeftToRight 20s ease-in-out infinite alternate;
}

.circleBox {
  position: relative;
  height: 50vh;
  width: auto;
  display: block;
  max-width: 50vw;
  margin: 0 auto;
  text-align: center;
  min-width: 310px;
  min-height: 310px;
  margin-top: -10vw;
  margin-bottom: -25vw;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
  opacity: 0;
  transform-origin: center;
}
.circleBox svg {
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.1;
  transition: all 0.5s ease-in-out;
}
.circleBox .centerlogo {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  height: 10vh;
  width: auto;
  max-width: 7vw;
  margin: 0 auto;
  text-align: center;
  min-width: 110px;
  min-height: 110px;
}
.circleBox img {
  transform: scale(1);
  display: block;
  width: 100%;
  height: auto;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
  50% {
    transform: scale(1.1);
  }
}
.circleBox .infobox {
  position: relative;
  margin-top: 2.5vw;
}
.circleBox .infobox .Typewriter {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 30px;
  text-shadow: 2px 2px 50px #50dbff;
}
.circleBox h2 {
  margin: 0px 0px 20px;
  text-align: center;
  font-size: 2vw;
  color: #00ffff;
  text-transform: uppercase;
}
.circleBox p {
  font-size: 1.5vw;
  margin: 0px 0px 10px;
  color: #08d5c6;
}

@keyframes keyLeftToRight {
  to {
    transform: rotate(360deg);
  }
}
.cirRightToLeft {
  transform-origin: center;
  animation: keyRightToLeft 15s linear infinite;
}

@keyframes keyRightToLeft {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.righttoleft {
  transform-origin: center;
  animation: righttoleft 6s linear infinite reverse;
}

@keyframes righttoleft {
  to {
    transform: rotate(-360deg);
  }
}
.lefttoright {
  transform-origin: center;
  animation: righttoleft 6s linear infinite;
}

@keyframes lefttoright {
  to {
    transform: rotate(-360deg);
  }
}
.borderboxmain {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -1;
  opacity: 0;
  transition: all 1s ease-in-out;
}
.borderboxmain img {
  width: calc(100% - 2vw);
  height: auto;
  display: block;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
}
.borderboxmain img.linetopleft {
  top: 1vw;
  left: 1vw;
  -o-object-position: left;
     object-position: left;
}
.borderboxmain img.linebottomright {
  bottom: 1vw;
  right: 1vw;
  -o-object-position: right;
     object-position: right;
}

.dashboard {
  padding: 5vw;
  position: relative;
}
.dashboard .box {
  padding: 100px;
  width: 100%;
  background-color: #111111;
}

.oc_row .oc_mobile {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1.5vw;
  opacity: 0;
  transition: all 1s ease-in-out;
}
.oc_row .oc_mobile .innerbox {
  position: relative;
  height: 15vw;
  width: auto;
  display: block;
  max-height: 91px;
  min-height: 92px;
  margin-block: 3.5vw;
  padding-inline: 10px;
}
.oc_row .oc_mobile .innerbox svg {
  width: 100%;
  height: 100%;
  display: block;
}
.oc_row .oc_mobile .innerbox .centerlogo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-width: 15vh;
  max-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-decoration: none;
}
.oc_row .oc_mobile .innerbox h2 {
  margin: 10px 0px 10px;
  text-align: center;
  font-size: 18px;
  color: #00ffff;
  text-transform: uppercase;
}

.middelBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 100px);
}

.boxBottomLeft {
  max-width: 400px;
}
.boxBottomLeft .innerbox {
  position: relative;
  height: 15vw;
  width: auto;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  max-height: 115px;
  text-align: center;
  min-height: 115px;
}
.boxBottomLeft .innerbox svg {
  width: 100%;
  height: 100%;
  display: block;
}
.boxBottomLeft .innerbox .centerlogo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-width: 15vh;
  max-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-decoration: none;
}
.boxBottomLeft .innerbox h2 {
  margin: 10px 0px 10px;
  text-align: center;
  font-size: 18px;
  color: #00ffff;
  text-transform: uppercase;
}

.progressbox {
  position: relative;
  margin-bottom: 30px;
  padding: 15px 50px;
}
.progressbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/boxframleft.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: left center;
  z-index: -1;
}
.progressbox::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("../images/boxframright.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: right center;
  z-index: -1;
}
.progressbox .progressboxinr {
  margin-bottom: 50px;
}
.progressbox .progressboxinr p {
  margin: 0px 0px 5px;
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 18px;
}
.progressbox .progress {
  background-color: transparent;
  border-radius: 0px;
  height: 28px;
  display: flex;
  align-items: center;
}
.progressbox .progress span {
  margin-left: 10px;
  font-size: 18px;
}
.progressbox .progress-bar-striped {
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progressbox .progress-bar {
  height: 28px;
  max-width: 0%;
  transition: all 1s ease-in-out;
  transition-delay: 1s;
}

.boxTopLeft {
  position: relative;
  max-width: 600px;
}
.boxTopLeft .chartBox {
  position: relative;
  text-align: center;
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
}
.boxTopLeft .chartBox::before, .boxTopLeft .chartBox::after {
  content: "";
  position: absolute;
  top: 0;
  width: 70px;
  height: calc(100% - 20px);
  z-index: 1;
}
.boxTopLeft .chartBox::before {
  left: 0;
  background-image: linear-gradient(to right, #000000, transparent);
}
.boxTopLeft .chartBox::after {
  right: 0;
  background-image: linear-gradient(to left, #000000, transparent);
}
.boxTopLeft .chartBox .chartjs-tooltip {
  display: none;
}
.boxTopLeft .chartBox P {
  margin: 10px 0 0;
}
.boxTopLeft .chartBoxInner {
  display: block;
  align-items: center;
  position: relative;
  animation: marqueeChart 10s linear infinite;
  opacity: 0;
  height: 68px;
}
@keyframes marqueeChart {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.boxTopLeft .chartData {
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
}
.boxTopLeft .chartData img {
  padding-left: 20px;
  width: 100%;
}
.boxTopLeft .chartData span {
  position: absolute;
  bottom: 15px;
  right: 20px;
}
.boxTopLeft .boxline {
  margin-top: 50px;
  width: 100%;
  height: auto;
  display: block;
}

.boxTopRight {
  max-width: 600px;
  margin-left: auto;
  position: relative;
}
.boxTopRight .progressCirclemain {
  margin-left: auto;
  align-items: flex-start;
}
.boxTopRight .progrfour {
  flex: 0 0 50%;
  max-width: 50%;
}
.boxTopRight .progressCirclebox {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 10px;
}
.boxTopRight .progressCirclebox .svgCirBox {
  position: relative;
  width: 112px;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox {
  position: relative;
  margin: 0 auto;
  width: 112px;
  height: auto;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox img {
  height: 112px;
  width: 110px;
  mix-blend-mode: hue;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox .single-chart {
  width: 33%;
  justify-content: space-around;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox .circular-chart {
  display: block;
  margin: 0px auto;
  max-width: 100%;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox .circle-bg {
  fill: none;
  stroke: none;
  stroke-width: 3.5;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox .circle {
  fill: none;
  stroke-width: 3.5px;
  stroke-linecap: square;
  transition: all 1s ease-in-out;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox .circular-chart.orange .circle {
  stroke: #02ffff;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox .percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox .mainbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.boxTopRight .progressCirclebox .svgCirBox .mainbox svg.circular-chart {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 119px;
  height: 112px;
  z-index: -1;
}
.boxTopRight .progressCirclebox svg {
  width: 100%;
  height: 100%;
  display: block;
}
.boxTopRight .progressCirclebox svg .clr {
  animation: col 2s linear 1;
}
.boxTopRight .progressCirclebox svg .clr1 {
  animation-delay: 1s;
}
.boxTopRight .progressCirclebox svg .clr2 {
  animation-delay: 1.5s;
}
.boxTopRight .progressCirclebox svg .clr3 {
  animation-delay: 2s;
}
.boxTopRight .progressCirclebox svg .clr4 {
  animation-delay: 2.5s;
}
.boxTopRight .progressCirclebox svg .clr5 {
  animation-delay: 3s;
}
.boxTopRight .progressCirclebox svg .clr6 {
  animation-delay: 3.5s;
}
.boxTopRight .progressCirclebox svg .clr7 {
  animation-delay: 4s;
}
.boxTopRight .progressCirclebox svg .clr8 {
  animation-delay: 4.5s;
}
.boxTopRight .progressCirclebox svg .clr9 {
  animation-delay: 5s;
}
.boxTopRight .progressCirclebox svg .clr10 {
  animation-delay: 5.5s;
}
@keyframes col {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.boxTopRight .progressCirclebox span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bolder;
}
.boxTopRight .progressCirclebox h3 {
  margin: 10px 0px 0px;
  text-align: center;
  font-size: 14px;
  color: #00b8bc;
}
.boxTopRight .chartData {
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
}
.boxTopRight .chartData img {
  padding-left: 20px;
  width: 100%;
}
.boxTopRight .chartData span {
  position: absolute;
  bottom: 15px;
  left: 30px;
  font-size: 20px;
  word-spacing: 6px;
}
.boxTopRight .boxline {
  top: 50%;
  margin-top: 50px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: -1;
}
.boxTopRight .boxline::after {
  content: "APR";
  top: 10px;
  left: 40px;
  width: 100%;
  position: absolute;
  font-size: 25px;
}

.boxBottomRight {
  position: relative;
  height: 100%;
  width: 100%;
  margin-left: auto;
  align-items: center;
}
.boxBottomRight .border_line {
  position: relative;
}
.boxBottomRight .border_line::before {
  content: "APR";
  top: 20px;
  left: 390px;
  width: 100%;
  position: absolute;
  font-size: 25px;
}
.boxBottomRight .border_line img {
  position: absolute;
  left: 390px;
}
.boxBottomRight .progressbox {
  position: relative;
  margin-bottom: 15px;
  padding: 15px 50px;
  max-width: 350px;
  width: 320px;
  margin-left: auto;
  height: 320px;
}
.boxBottomRight .progressboxinr p {
  margin: 0px 0px 5px;
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 15px;
}
.boxBottomRight .progressboxinr .progress {
  background-color: transparent;
  border-radius: 0px;
  height: 24px;
  display: flex;
  align-items: center;
}

.connectBtnMain {
  transition: all 0.15s ease-in-out;
}
.connectBtnMain .bg-button {
  display: inline-block;
  position: relative;
  background-color: transparent;
  color: #00d7c3;
  border: none;
  padding: 0px;
}
.connectBtnMain .bg-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #01ffff;
  z-index: 0;
}
.connectBtnMain .bg-button svg {
  transition: all 0.15s ease-in-out;
  width: 100%;
  display: block;
  max-width: 250px;
}
.connectBtnMain.poolsBoxHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}
.connectBtnMain.poolsBoxHead .backIcon {
  text-decoration: none;
  margin-bottom: 20px;
}
.connectBtnMain.poolsBoxHead h2 {
  font-size: 22px;
  font-weight: 400;
  margin: 0px;
  color: #01ffff;
}

.connectBtnMain svg:hover {
  filter: drop-shadow(0px 0px 13px #00d7c3);
}

.poolsBox {
  position: relative;
  padding: 50px 40px;
  width: 100%;
  background-color: #000000;
  margin-top: 10px;
  margin-bottom: 50px;
  min-height: 150px;
}
.poolsBox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-image: url("../images/poolbox/poolboxtopline.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
}
.poolsBox::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-image: url("../images/poolbox/poolboxbottomline.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;
}
.poolsBox .poolsBoxInner {
  background-color: #002d2e;
  padding: 20px;
  border-radius: 2px;
}
.poolsBox .poolBoxDefault {
  text-align: center;
  padding: 30px 0px;
}
.poolsBox .poolBoxDefault svg {
  display: block;
  margin: 0 auto 15px;
  stroke: #01ffff;
}
.poolsBox .poolBoxDefault p {
  font-size: 18px;
  margin: 0px;
  color: #01ffff;
  font-weight: 400;
  letter-spacing: 1px;
}

.swap {
  position: relative;
}
.swap .swap-container .button-1 {
  background-color: transparent;
  transition: all 0.15s ease-in-out;
  color: #00d7c3;
  z-index: 99;
}
.swap .swap-container .button-1:hover {
  box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
}
.swap .swap-container .swap-main-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.swap .swap-container .swap-main-box svg {
  width: 100%;
  display: block;
  height: auto;
}
.swap .swap-container .swap-main-box .token-box-1 {
  position: relative;
  max-width: 600px;
}
.swap .swap-container .swap-main-box .token-box-1 svg {
  position: relative;
  z-index: -1;
}
.swap .swap-container .swap-main-box .token-box-1 .main-box {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
  justify-content: flex-start;
  width: 450px;
}
.swap .swap-container .swap-main-box .token-box-1 .form-group {
  display: flex;
  width: auto;
  margin-right: auto;
  flex-direction: column;
  width: 50%;
  justify-content: left;
}
.swap .swap-container .swap-main-box .token-box-1 .form-group input[type=number] {
  outline: none;
  background: none;
  border: none;
  font-size: 24px;
  color: #00ffff;
}
.swap .swap-container .swap-main-box .token-box-1 .form-group input[type=number]::-moz-placeholder {
  color: #00ffff;
  background: transparent;
  outline: none;
}
.swap .swap-container .swap-main-box .token-box-1 .form-group input[type=number]::placeholder {
  color: #00ffff;
  background: transparent;
  outline: none;
}
.swap .swap-container .swap-main-box .token-box-1 .form-group input[type=text]:focus {
  outline: none;
}
.swap .swap-container .swap-main-box .token-box-1 label {
  display: inline-block;
  margin-bottom: 60px;
  font-size: 25px;
  color: #00ffff;
}
.swap .swap-container .swap-main-box .token-box-1 .token-balance .token-currency {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.swap .swap-container .swap-main-box .token-box-1 .token-balance .token-currency .bg-logo {
  background: black;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-inline-end: 15px;
  justify-content: center;
  align-items: center;
}
.swap .swap-container .swap-main-box .token-box-2 {
  max-width: 95px;
  height: auto;
  margin-inline: auto;
  margin-block: 10px;
}
.swap .swap-container .swap-main-box .token-box-2 svg {
  width: 100px;
  height: auto;
  transition: all 0.3s ease-in-out;
}
.swap .swap-container .swap-main-box .token-box-2 svg:hover {
  filter: drop-shadow(0px 0px 10px #00d7c3);
}
.swap .swap-container .swap-main-box .token-box-3 {
  position: relative;
  max-width: 600px;
}
.swap .swap-container .swap-main-box .token-box-3 svg {
  position: relative;
  z-index: -1;
}
.swap .swap-container .swap-main-box .token-box-3 .main-box {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
  justify-content: flex-start;
  width: 450px;
}
.swap .swap-container .swap-main-box .token-box-3 .form-group {
  display: flex;
  width: auto;
  margin-right: auto;
  flex-direction: column;
  width: 50%;
  justify-content: left;
}
.swap .swap-container .swap-main-box .token-box-3 .form-group input[type=number] {
  outline: none;
  background: none;
  border: none;
  font-size: 24px;
  color: #00ffff;
}
.swap .swap-container .swap-main-box .token-box-3 .form-group input[type=number]::-moz-placeholder {
  color: #00ffff;
  background: transparent;
  outline: none;
}
.swap .swap-container .swap-main-box .token-box-3 .form-group input[type=number]::placeholder {
  color: #00ffff;
  background: transparent;
  outline: none;
}
.swap .swap-container .swap-main-box .token-box-3 .form-group input[type=number]:focus {
  outline: none;
}
.swap .swap-container .swap-main-box .token-box-3 label {
  display: inline-block;
  margin-bottom: 60px;
  font-size: 25px;
  color: #00ffff;
}
.swap .swap-container .swap-main-box .token-box-3 .token-balance .token-currency {
  display: flex;
  align-items: center;
}
.swap .swap-container .swap-main-box .token-box-3 .token-balance .token-currency .bg-logo {
  background: black;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-inline-end: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swap .swap-container .swap-main-box .swapNetwork {
  position: relative;
  max-width: 600px;
  margin-top: 30px;
}
.swap .swap-container .swap-main-box .swapNetwork .infoIcon {
  position: absolute;
  top: -2px;
  left: 0;
  fill: #ffffff;
  width: 25px;
}
.swap .swap-container .swap-main-box .swapNetwork .topBtnArrow {
  position: absolute;
  top: -2px;
  right: 0;
  fill: #ffffff;
  width: 22px;
  background-color: #ffffff;
  z-index: -1;
  border-radius: 50px;
}
.swap .swap-container .swap-main-box .swapNetwork .Collapsible__trigger {
  padding-left: 35px;
  width: 100%;
  display: block;
}
.swap .swap-container .swap-main-box .swapNetwork .Collapsible {
  position: relative;
}
.swap .swap-container .swap-main-box .swapNetwork .Collapsible::after {
  content: "";
}
.swap .swap-container .swap-main-box .swapNetwork .Collapsible__contentOuter {
  margin-top: 25px;
}
.swap .swap-container .swap-main-box .swapNetwork .Collapsible__contentOuter .Collapsible__contentInner {
  border: 1px solid #01ffff;
  border-radius: 10px;
  padding: 20px;
}
.swap .swap-container .swap-main-box .swapNetwork .Collapsible__contentOuter .Collapsible__contentInner p {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0px 0px 10px;
  font-size: 14px;
}
.swap .swap-container .swap-main-box .swapNetwork .Collapsible__contentOuter .Collapsible__contentInner p span {
  font-size: 13px;
}
.swap .swap-container .swap-main-box .token-button {
  display: flex;
  width: 100%;
  margin-top: 50px;
  border: none;
  max-width: initial;
}
.swap .swap-container .swap-main-box .token-button span {
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #01ffff;
  z-index: 9;
  font-size: 20px;
}
.swap .swap-container .swap-main-box .token-button svg {
  z-index: 0;
}
.swap .swap-container button {
  margin-left: auto;
  background: transparent;
  color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  max-width: 300px;
  position: relative;
}
.swap .swap-container button img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  filter: invert(1);
  transition: all 0.3s ease-in-out;
}
.swap .swap-container button span {
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #01ffff;
  z-index: 0;
}
.swap .swap-container button .bg-button {
  position: relative;
  transition: all 0.5s;
  z-index: 99;
  max-width: 380px;
}
.swap .swap-container button .bg-button svg {
  width: 100%;
  height: 100%;
}
.swap .swap-container button .bg-button svg .lefttoright {
  transition: all 0.3s ease-in-out;
}
.swap .swap-container button .bg-button:hover {
  filter: drop-shadow(0px 0px 13px #00d7c3);
}
.swap .swap-container button.active img {
  transform: translate(-50%, -50%) scaleY(-1);
}
.swap .swap-container button.active svg .lefttoright {
  animation: righttoleft 6s linear infinite reverse;
}

.nfts {
  position: relative;
}
.nfts .nfts-container .search-box-content {
  display: flex;
  align-items: center;
  flex: 0 0 48%;
  max-width: 48%;
}
.nfts .nfts-container .search-box-content .empty-space {
  display: block;
  width: 33.33%;
  height: auto;
}
.nfts .nfts-container .search-box-content .search-bar-box {
  margin-left: auto;
  display: flex;
  justify-content: center;
}
.nfts .nfts-container .search-box-content .search-bar-box .search-bar {
  display: flex;
  justify-content: flex-end;
}
.nfts .nfts-container .search-box-content .search-bar-box .search-bar .mainLoginInput {
  text-align: center;
}
.nfts .nfts-container .search-box-content .search-bar-box .search-bar .mainLoginInput::-webkit-input-placeholder {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #008686;
}
.nfts .nfts-container .search-box-content .search-bar-box .search-bar .mainLoginInput::-moz-placeholder {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #008686;
}
.nfts .nfts-container .search-box-content .search-bar-box .search-bar .mainLoginInput:-ms-input-placeholder {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #008686;
}
.nfts .nfts-container .search-box-content .nfts-button {
  display: block;
  width: 33.33%;
}
.nfts .nfts-container .nfts-outer-button-border {
  border: 2px solid #006F76;
  background: transparent;
  padding: 2px 5px;
  border-inline-width: 4px;
  position: relative;
  left: 8px;
  flex: 0 0 50%;
  max-width: 480px;
}
.nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border {
  border: 2px solid #002626;
  background: transparent;
  padding: 3px;
  border-inline-width: 4px;
}
.nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp {
  margin-inline: auto;
}
.nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  background: #004646;
  outline: none;
}
.nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp ul {
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: space-between;
}
.nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp ul li {
  background-color: #002626;
  color: #01ffff;
  padding: 10px 24px;
  cursor: pointer;
  border: none;
  font-size: 18px;
  margin: 0;
  width: 100%;
  flex: 0 0 33.33%;
  max-width: 33.3%;
  text-align: center;
}
.nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp button {
  background-color: #002626;
  color: #01ffff;
  padding: 10px 24px;
  cursor: pointer;
  float: left;
  border: none;
  font-size: 18px;
}
.nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp button:hover {
  background-color: #004646;
}
.nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp:after {
  content: "";
  clear: both;
  display: table;
}
.nfts .nfts-container .scroll-border {
  position: relative;
  width: 100%;
  margin: 0px auto;
}
.nfts .nfts-container .scroll-border .scroll-shadow {
  display: none;
}
.nfts .nfts-container .scroll-border .scroll {
  position: relative;
}
.nfts .nfts-container .scroll::-webkit-scrollbar {
  width: 5px;
  max-height: 50%;
}
.nfts .nfts-container .scroll::-webkit-scrollbar-track {
  background: #00A5A5;
  max-height: 50%;
  height: 50;
}
.nfts .nfts-container .scroll::-webkit-scrollbar-thumb {
  background: #00FFFF;
}
.nfts .nfts-container .nfts-content-area {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto 0;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 {
  padding: 17px 15px;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 20px);
  max-width: calc(50% - 20px);
  margin: 0px 10px 20px;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1::before {
  position: absolute;
  content: "";
  background: url(../images/before.svg) center/contain no-repeat;
  background-size: 100%;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-position: top center;
  z-index: -1;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1::after {
  position: absolute;
  content: "";
  background: url(../images/after.svg) center/contain no-repeat;
  background-size: 100%;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  background-position: bottom center;
  z-index: -1;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 {
  height: 100%;
  width: 100%;
  border: 2px solid #004646;
  background: #002D2E;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box {
  height: 100%;
  width: 100%;
  padding: 12px;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-sub-input-box {
  background: #004849;
  height: 110px;
  width: 100%;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box {
  display: flex;
  justify-content: space-between;
  margin: 0 50px 0 35px;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-1 svg {
  margin-top: -10px;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 span {
  display: block;
  text-align: end;
  color: #01FFFF;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-1 {
  font-size: 37px;
  margin-bottom: 7px;
}
.nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-2 {
  font-size: 21px;
}
.nfts .nfts-container label {
  position: relative;
}
.nfts .nfts-container label::after {
  content: "";
  position: absolute;
  right: 0;
  top: -17px;
  bottom: 0;
  width: 433px;
  height: 74px;
  background: url("../images/button-clip.png") center/contain no-repeat;
  z-index: -1;
}
.nfts .nfts-container input {
  padding: 10px 30px;
}
.nfts .nfts-container input[type=search] {
  background: #002626;
  outline: none;
  border: 1px solid #004747;
  font-size: 26px;
  flex-grow: 1;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
.nfts .nfts-container input[type=search]::-moz-placeholder {
  color: #008686;
  z-index: 9;
  letter-spacing: 0.1rem;
}
.nfts .nfts-container input[type=search]::placeholder {
  color: #008686;
  z-index: 9;
  letter-spacing: 0.1rem;
}
.nfts .nfts-container [type=search] {
  outline-offset: none;
  margin: 0px auto;
  display: block;
  text-align: center;
  padding: 4px 18px;
  border-radius: 20px;
  border: 1px solid #707070;
  color: #008686;
}
.nfts .nfts-container .custom-field input:focus label::before {
  content: "";
  left: 55px;
}
.nfts .nfts-container .button-1 {
  background-color: transparent;
  border: 3px solid #00d7c3;
  transition: all 0.15s ease-in-out;
  color: #00d7c3;
  z-index: 99;
}
.nfts .nfts-container .button-1:hover {
  box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
  border: 3px solid #00d7c3;
}
.nfts .nfts-container .nfts-main-box {
  width: -moz-max-content;
  width: max-content;
  margin-inline: auto;
  padding: 30px;
}
.nfts .nfts-container button {
  margin-left: auto;
  background: transparent;
  color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  max-width: 300px;
}
.nfts .nfts-container button span {
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #01ffff;
  z-index: 0;
  width: 100%;
}
.nfts .nfts-container button .bg-button {
  position: relative;
  transition: all 0.5s;
  mix-blend-mode: hard-light;
  z-index: 99;
}
.nfts .nfts-container button .bg-button svg {
  width: 100%;
  height: 100%;
}
.nfts .nfts-container button .bg-button:hover {
  mix-blend-mode: normal;
  box-shadow: 0 0 10px 0 cyan inset, 0 0 20px 2px cyan;
}
.nfts .nfts-container button.token-button {
  margin: 25px auto 0px;
  position: relative;
}
.nfts .nfts-container button.token-button span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.nfts .nfts-container button.token-button .bg-button svg {
  margin: 0px;
}

.gridlistview {
  position: relative;
}
.gridlistview .farming-container .farming-button-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9999;
  background-image: linear-gradient(#000000 60%, rgba(255, 0, 0, 0));
  padding-top: 12px;
  padding-bottom: 20px;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 45%;
  max-width: 45%;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border {
  border: 2px solid #006F76;
  background: transparent;
  padding: 2px 6px;
  border-inline-width: 4px;
  margin-left: 0px;
  width: calc(100% - 160px);
  max-width: 250px;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border {
  border: 2px solid #002626;
  background: transparent;
  padding: 3px;
  border-inline-width: 4px;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp {
  margin-inline: auto;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  background: #004646;
  outline: none;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul {
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
  background-color: #002626;
  color: #01ffff;
  padding: 10px 6px;
  cursor: pointer;
  border: none;
  font-size: 18px;
  margin: 0;
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp button {
  background-color: #002626;
  color: #01ffff;
  padding: 15px 38px;
  cursor: pointer;
  float: left;
  border: none;
  font-size: 26px;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp button:hover {
  background-color: #004646;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .drop-down-btn {
  display: block;
}
.gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp:after {
  content: "";
  clear: both;
  display: table;
}
.gridlistview .farming-container .farming-button-area .empty-area {
  width: 580px;
}
.gridlistview .farming-container .farming-button-area .view-btn-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 160px;
}
.gridlistview .farming-container .farming-button-area .view-btn-group .list-btn {
  margin-inline-end: 25px;
}
.gridlistview .farming-container .farming-button-area .view-btn-group .list-btn button {
  margin-left: auto;
  background: transparent;
  color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.gridlistview .farming-container .farming-button-area .view-btn-group .gird-btn {
  margin-inline-end: 20px;
}
.gridlistview .farming-container .farming-button-area .view-btn-group .gird-btn button {
  border: none;
}
.gridlistview .farming-container .search-box-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 calc(55% - 175px);
  max-width: calc(55% - 175px);
}
.gridlistview .farming-container .search-box-content .empty-space {
  display: block;
  height: auto;
}
.gridlistview .farming-container .search-box-content .search-bar-box {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.gridlistview .farming-container .search-box-content .search-bar-box .search-bar {
  display: flex;
  justify-content: flex-end;
}
.gridlistview .farming-container .search-box-content .search-bar-box .search-bar .mainLoginInput {
  text-align: center;
}
.gridlistview .farming-container .search-box-content .search-bar-box .search-bar .mainLoginInput::-webkit-input-placeholder {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #008686;
}
.gridlistview .farming-container .search-box-content .search-bar-box .search-bar .mainLoginInput::-moz-placeholder {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #008686;
}
.gridlistview .farming-container .search-box-content .search-bar-box .search-bar .mainLoginInput:-ms-input-placeholder {
  font-family: FontAwesome;
  font-weight: normal;
  overflow: visible;
  vertical-align: top;
  display: inline-block !important;
  padding-left: 5px;
  padding-top: 2px;
  color: #008686;
}
.gridlistview .farming-container .search-box-content .nfts-button {
  display: block;
  width: 33.33%;
}
.gridlistview .farming-container .farming-outer-button-border-1 {
  border: 2px solid #006F76;
  background: transparent;
  padding: 2px 6px;
  border-inline-width: 4px;
  flex: 0 0 135px;
  max-width: 135px;
  margin-left: 10px;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border {
  border: 2px solid #002626;
  background: transparent;
  padding: 3px;
  border-inline-width: 4px;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp {
  margin-inline: auto;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  background: #004646;
  outline: none;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp .btn-group {
  width: 100%;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul {
  margin-bottom: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  top: calc(100% + 8px) !important;
  transform: none !important;
  right: -18px !important;
  left: unset !important;
  filter: drop-shadow(0px 1px 2px #00d7c3);
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
  background-color: #002626;
  color: #01ffff;
  padding: 10px 24px;
  cursor: pointer;
  border: none;
  font-size: 18px;
  margin: 0;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
  padding: 10px;
  color: #fff;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item:hover {
  color: #01ffff;
  background-color: #004646;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
  background-color: #002626;
  color: #01ffff;
  padding: 8px 6px;
  cursor: pointer;
  float: left;
  border: none;
  font-size: 22px;
  border-radius: 0;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button:hover {
  background-color: #004646;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .drop-down-btn {
  display: block;
}
.gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp:after {
  content: "";
  clear: both;
  display: table;
}
.gridlistview .farming-container .scroll-border {
  position: relative;
  width: 100%;
  margin: 0px auto;
}
.gridlistview .farming-container .scroll-border .scroll-shadow {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 100%;
  height: 150px;
  background-image: linear-gradient(transparent, black);
  z-index: 1;
  max-width: 100%;
}
.gridlistview .farming-container .scroll::-webkit-scrollbar {
  width: 5px;
  max-height: 50%;
}
.gridlistview .farming-container .scroll::-webkit-scrollbar-track {
  background: #00A5A5;
  max-height: 50%;
  height: 50;
}
.gridlistview .farming-container .scroll::-webkit-scrollbar-thumb {
  background: #00FFFF;
}
.gridlistview .farming-container .farming-content-area {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto 30px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 {
  position: relative;
  height: 100%;
  border: 4px solid aqua;
  padding: 4px;
  background-color: #012728;
  margin: 0px 10px 30px;
  flex: 0 0 calc(33.3333333333% - 20px);
  max-width: calc(33.3333333333% - 20px);
}
.gridlistview .farming-container .farming-content-area .farming-box-1::before {
  content: "";
  position: absolute;
  bottom: -6px;
  right: -4px;
  background-image: url("../images/farming-box-left-frame.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 0;
  transform: scaleX(-1);
  width: 54px;
  height: 94px;
  background-position: bottom right;
}
.gridlistview .farming-container .farming-content-area .farming-box-1::after {
  content: "";
  position: absolute;
  top: -10px;
  background-image: url("../images/farming-box-right-frame.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 0;
  transform: scaleX(-1);
  left: -5px;
  width: 74px;
  height: 184px;
  background-position: top left;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: 25px;
  color: #01FFFF;
  text-transform: uppercase;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-1 .farming-logo-box {
  margin-right: 11px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 {
  padding-inline: 14px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-transform: uppercase;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 .span-letter {
  margin-top: 8px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
  text-align: center;
  font-size: 16px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 p {
  margin: 0;
  color: #01FFFF;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 p img {
  margin-inline: 9px;
  display: none;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 p span {
  display: block;
  color: #fff;
  margin-top: 8px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
  display: none;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
  margin-bottom: 0;
  font-size: 22px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-letter {
  margin-right: 5px;
  color: #01FFFF;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
  display: block;
  margin-top: 8px;
  font-size: 18px;
  color: #01FFFF;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  max-width: 120px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
  background: url(../images/farming-harvest-btn.svg);
  padding: 4px;
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-size: contain;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
  padding: 4px 10px;
  background: #004848;
  border: 1px solid #006364;
  font-size: 16px;
  display: initial;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.6rem;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
  display: block;
  font-size: 24px;
  margin-block: 9px;
  color: #01FFFF;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-number {
  display: block;
  margin-top: 8px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
  width: 100%;
  position: relative;
  display: flex;
  height: 100%;
  text-align: end;
  max-width: 120px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
  background: url(../images/farming-harvest-btn.svg);
  padding: 4px;
  background-repeat: no-repeat;
  background-size: contain;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
  padding: 4px 5px;
  background: #004848;
  border: 1px solid #006364;
  font-size: 16px;
  display: initial;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-3 {
  margin-block: 25px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-3 button {
  background: transparent;
  color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: none;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #01ffff;
  z-index: 0;
  font-size: 18px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
  position: relative;
  transition: all 0.5s;
  mix-blend-mode: hard-light;
  z-index: 99;
  max-width: 280px;
}
.gridlistview .farming-container .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button svg#Group_22317 {
  width: 100%;
  height: 100%;
}
.gridlistview .farming-container label {
  position: relative;
}
.gridlistview .farming-container label::after {
  content: "";
  position: absolute;
  right: 0;
  top: -17px;
  bottom: 0;
  width: 100%;
  height: 74px;
  background: url("../images/button-clip.png") center/cover no-repeat;
  z-index: -1;
}
.gridlistview .farming-container input {
  padding: 10px 30px;
}
.gridlistview .farming-container input[type=search] {
  background: #002626;
  outline: none;
  border: 1px solid #004747;
  font-size: 26px;
  flex-grow: 1;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
.gridlistview .farming-container input[type=search]::-moz-placeholder {
  color: #008686;
  z-index: 9;
  letter-spacing: 0.1rem;
}
.gridlistview .farming-container input[type=search]::placeholder {
  color: #008686;
  z-index: 9;
  letter-spacing: 0.1rem;
}
.gridlistview .farming-container [type=search] {
  outline-offset: none;
  margin: 0px auto;
  display: block;
  text-align: center;
  padding: 4px 18px;
  border-radius: 20px;
  border: 1px solid #707070;
  color: #008686;
  width: 100%;
}
.gridlistview .farming-container .custom-field input:focus label::before {
  content: "";
  left: 55px;
}
.gridlistview .farming-container .button-1 {
  background-color: transparent;
  border: 3px solid #00d7c3;
  transition: all 0.15s ease-in-out;
  color: #00d7c3;
  z-index: 99;
}
.gridlistview .farming-container .button-1:hover {
  box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
  border: 3px solid #00d7c3;
}
.gridlistview .farming-container .nfts-main-box {
  width: -moz-max-content;
  width: max-content;
  margin-inline: auto;
  padding: 30px;
}
.gridlistview .farming-container button {
  margin-left: auto;
  background: transparent;
  color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  max-width: 300px;
}
.gridlistview .farming-container button:hover svg {
  filter: drop-shadow(0px 0px 10px #00d7c3);
}
.gridlistview .farming-container button span {
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #01ffff;
  z-index: 0;
}
.gridlistview .farming-container button .bg-button {
  position: relative;
  transition: all 0.5s;
  mix-blend-mode: hard-light;
  z-index: 99;
}
.gridlistview .farming-container button .bg-button svg {
  width: 100%;
  height: 100%;
}
.gridlistview .farming-container button .bg-button:hover {
  mix-blend-mode: normal;
  box-shadow: 0 0 10px 0 cyan inset, 0 0 20px 2px cyan;
}

.gridlistview.listviewmode .farming-content-area .farming-box-1 {
  display: flex;
  max-height: 100px;
  overflow: hidden;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  transition: all 0.5s;
  flex: 0 0 100%;
  max-width: 100%;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: 25px;
  color: #01FFFF;
  text-transform: uppercase;
  flex: 0 0 20%;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-2 {
  padding-inline: 14px;
  display: flex;
  width: 100%;
  flex: 0 0 74%;
  flex-wrap: wrap;
  justify-content: space-around;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 55%;
  margin-top: 14px;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
  display: flex;
  align-items: center;
  margin-inline-start: 300px;
  z-index: 99;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex: 0 0 50%;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
  height: auto;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
  height: auto;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-3 {
  display: table-row;
  width: 100%;
  flex: 0 0 100%;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-3 button {
  background: transparent;
  color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 400px;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
  font-size: 22px;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
  position: relative;
  transition: all 0.5s;
  mix-blend-mode: hard-light;
  z-index: 99;
  max-width: 506px;
}
.gridlistview.listviewmode .farming-content-area .farming-box-1.height-slider {
  max-height: 400px;
}

.boxTopLeft,
.boxTopRight,
.boxBottomLeft,
.boxBottomRight {
  transform: scale(0);
  transform-origin: top right;
  transition: all 1s ease-in-out;
  opacity: 0;
}

.boxTopLeft {
  transform-origin: bottom right;
}

.boxTopRight {
  transform-origin: bottom left;
}

.boxBottomLeft {
  transform-origin: top right;
}

.boxBottomRight {
  transform-origin: bottom left;
}

.pageLoaded .boxTopLeft,
.pageLoaded .boxTopRight,
.pageLoaded .boxBottomLeft,
.pageLoaded .boxBottomRight {
  transform: scale(1);
  opacity: 1;
}
.pageLoaded .circleBox svg {
  opacity: 1;
}
.pageLoaded .progressbox .progress-bar {
  max-width: 100%;
}
.pageLoaded .boxTopLeft .chartBoxInner {
  display: flex;
  opacity: 1;
}
.pageLoaded::before {
  opacity: 0.08;
}
.pageLoaded .boxTopRight .progressCirclebox .svgCirBox .mainbox .circle {
  animation: progress 8s ease-out forwards;
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.pageLoaded .oc_row .oc_mobile,
.pageLoaded .borderboxmain {
  opacity: 1;
}

.loadDash .circleBox {
  transform: scale(1);
  opacity: 1;
}

.nftsHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0px;
  padding-top: 15px;
  z-index: 9999;
  background-image: linear-gradient(#000000 60%, rgba(255, 0, 0, 0));
  padding-bottom: 50px;
}

.ox_container {
  width: 1320px;
  max-width: calc(100% - 10vw);
  margin: 0 auto;
  padding: 0px;
}

#root {
  min-height: 100vh;
}

.modal.newPositionMdl {
  z-index: 999999;
}
.modal.newPositionMdl .modal-dialog {
  max-width: calc(100% - 40px);
  width: 1040px;
  margin-left: auto;
  margin-right: auto;
}
.modal.newPositionMdl .modal-dialog .modal-content {
  background-color: #012728;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  border: none;
  box-shadow: 0px 0px 120px 110px #000000;
}
.modal.newPositionMdl .modal-dialog .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  border-color: #274748;
  border-width: 1px;
}
.modal.newPositionMdl .modal-dialog .modal-header .btn-close {
  margin: 0px;
  flex: 0 0 16px;
  opacity: 1;
  max-width: 16px;
  filter: invert(1);
  order: 0;
}
.modal.newPositionMdl .modal-dialog .modal-header .modal-title {
  text-align: center;
  order: 1;
  font-size: 18px;
  padding-left: 40px;
}
.modal.newPositionMdl .modal-dialog .modal-header .headRight {
  order: 2;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}
.modal.newPositionMdl .modal-dialog .modal-header .headRight p {
  margin: 0px;
  font-size: 12px;
  color: #01ffff;
  cursor: pointer;
}
.modal.newPositionMdl .modal-dialog .modal-header .headRight .btn-group {
  margin-left: 15px;
}
.modal.newPositionMdl .modal-dialog .modal-header .headRight .btn-group button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  padding: 0px;
  margin: 0px;
}
.modal.newPositionMdl .titileBox {
  font-size: 16px;
  display: block;
  margin: 0 0 20px;
}
.modal.newPositionMdl .popMaininrBox .col-lg-6 {
  padding: 10px 30px;
}
.modal.newPositionMdl .selectPairBox .btn-group {
  margin: 0px 0px 10px;
  flex: 0 0 50%;
  max-width: 50%;
}
.modal.newPositionMdl .selectPairBox .btn-group button {
  background-color: #006364;
  color: #ffffff;
  font-size: 18px;
  border: 1px solid #ffffff;
  outline: none;
  box-shadow: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
}
.modal.newPositionMdl .feeTierBox {
  display: block;
  position: relative;
  border: 1px solid #3c6d6e;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
  padding-right: 80px;
}
.modal.newPositionMdl .feeTierBox p {
  font-size: 16px;
  margin: 0 0 3px;
}
.modal.newPositionMdl .feeTierBox span {
  display: block;
  font-size: 12px;
}
.modal.newPositionMdl .feeTierBox button {
  background-color: #006364;
  color: #ffffff;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  border: none;
  outline: none;
  font-size: 16px;
  padding: 6px 3px;
  min-width: 55px;
  border-radius: 2px;
}
.modal.newPositionMdl .bestForBox {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 20px;
}
.modal.newPositionMdl .bestForBox .bstfrbxInr {
  border: 1px solid #3c6d6e;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
  flex: 0 0 calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
  margin: 0px 5px 10px;
  position: relative;
  cursor: pointer;
}
.modal.newPositionMdl .bestForBox .bstfrbxInr .checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: #006364;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.modal.newPositionMdl .bestForBox .bstfrbxInr .checkbox svg {
  width: 15px;
  height: auto;
}
.modal.newPositionMdl .bestForBox .bstfrbxInr.active {
  outline: 2px solid #3c6d6e;
}
.modal.newPositionMdl .bestForBox .bstfrbxInr.active .checkbox {
  display: flex;
}
.modal.newPositionMdl .bestForBox .bstfrbxInr:hover {
  outline: 2px solid #111111;
}
.modal.newPositionMdl .bestForBox p {
  font-size: 18px;
  margin: 0 0 3px;
}
.modal.newPositionMdl .bestForBox span {
  display: block;
  font-size: 12px;
}
.modal.newPositionMdl .setPriceRangeBx {
  text-align: center;
  padding: 30px 0px;
}
.modal.newPositionMdl .setPriceRangeBx svg {
  stroke: #ffffff;
  margin-bottom: 20px;
}
.modal.newPositionMdl .setPriceRangeBx p {
  font-size: 20px;
}
.modal.newPositionMdl .setStatingPrice .infoBx {
  background-color: #004747;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.modal.newPositionMdl .setStatingPrice .infoBx p {
  margin: 0px;
  font-size: 14px;
}
.modal.newPositionMdl .setStatingPrice .inptBx {
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #3c6d6e;
}
.modal.newPositionMdl .setStatingPrice .inptBx input {
  width: 100%;
  border: none;
  padding: 8px;
  border-radius: 5px;
  font-size: 20px;
  outline: none;
  box-shadow: none;
  background-color: #183a3b;
  color: #ffffff;
}
.modal.newPositionMdl .setStatingPrice .currentPriceBx {
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #3c6d6e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal.newPositionMdl .setStatingPrice .currentPriceBx p {
  margin: 0px;
  font-size: 16px;
}
.modal.newPositionMdl .setStatingPrice .currentPriceBx b {
  font-size: 18px;
  margin: 0px 5px;
  font-weight: 500;
}
.modal.newPositionMdl .setStatingPrice .currentPriceBx span {
  font-size: 16px;
}
.modal.newPositionMdl .minMaxPrice {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.modal.newPositionMdl .minMaxPrice .minmxPrcInr {
  border: 1px solid #3c6d6e;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
  flex: 0 0 calc(50% - 10px);
  max-width: calc(50% - 10px);
  margin: 0px 5px 10px;
  text-align: center;
}
.modal.newPositionMdl .minMaxPrice .minmxPrcInr p {
  font-size: 12px;
}
.modal.newPositionMdl .minMaxPrice .inptBox {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
.modal.newPositionMdl .minMaxPrice .inptBox button {
  flex: 0 0 30px;
  max-width: 30px;
  background-color: #006364;
  color: #ffffff;
  border-radius: 50px;
  border: none;
  height: 30px;
  font-size: 20px;
}
.modal.newPositionMdl .minMaxPrice .inptBox input {
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  color: #ffffff;
}
.modal.newPositionMdl .minMaxPrice .inptBox .react-numeric-input {
  flex: 0 0 calc(100% - 60px);
  max-width: calc(100% - 60px);
}
.modal.newPositionMdl .minMaxPrice .inptBox .react-numeric-input input {
  width: 100%;
  text-align: center;
  border: none !important;
  outline: none;
  box-shadow: none;
  line-height: 1 !important;
  padding: 0px !important;
  font-size: 22px !important;
  background-color: transparent;
  color: #ffffff;
}
.modal.newPositionMdl .minMaxPrice .inptBox .react-numeric-input b {
  display: none;
}
.modal.newPositionMdl .minMaxPrice p {
  font-size: 18px;
  margin: 0 0 3px;
}
.modal.newPositionMdl .fullRngBtn {
  width: 100%;
  background-color: #006F76;
  color: #ffffff;
  border: none;
  line-height: 1.2;
  border-radius: 50px;
  padding: 10px;
  margin-top: 5px;
}
.modal.newPositionMdl .fullRngBtn:hover {
  background-color: #006364;
}
.modal.newPositionMdl .depositAmuntBx {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #006364;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.modal.newPositionMdl .depositAmuntBx:nth-last-child(1) {
  margin-bottom: 0px;
}
.modal.newPositionMdl .depositAmuntBx input {
  flex-basis: calc(100% - 140px);
  max-width: calc(100% - 140px);
  margin-right: 15px;
  width: 100%;
  border-radius: 5px;
  padding: 10px 5px;
  font-size: 24px;
  font-weight: 600;
  border: none;
  outline: none;
  background-color: transparent;
  color: #ffffff;
}
.modal.newPositionMdl .depositAmuntBx input::-moz-placeholder {
  opacity: 1;
  color: #ffffff;
}
.modal.newPositionMdl .depositAmuntBx input::placeholder {
  opacity: 1;
  color: #ffffff;
}
.modal.newPositionMdl .depositAmuntBx input::-webkit-outer-spin-button,
.modal.newPositionMdl .depositAmuntBx input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal.newPositionMdl .depositAmuntBx .rightBx {
  flex: 0 0 125px;
  max-width: 125px;
  text-align: right;
}
.modal.newPositionMdl .depositAmuntBx .rightBx .coinbx {
  margin: 0px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 4px 8px;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-left: auto;
  font-size: 16px;
}
.modal.newPositionMdl .depositAmuntBx .rightBx .coinbx img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50px;
}
.modal.newPositionMdl .depositAmuntBx .rightBx .maxBtn {
  border: none;
  margin-top: 4px;
  border-radius: 3px;
  font-size: 14px;
}
.modal.newPositionMdl .depositAmuntBx .rightBx span {
  font-size: 14px;
}
.modal.newPositionMdl .bxBtnMainFoot {
  width: 100%;
  background-color: #012728;
  font-size: 20px;
  border-radius: 5px;
  padding: 12px;
  color: #01ffff;
  border: 1px solid #01ffff;
  transition: all 0.3s ease-in-out;
}
.modal.newPositionMdl .bxBtnMainFoot:hover {
  filter: drop-shadow(0px 0px 10px rgba(1, 255, 255, 0.5));
}
.modal.selectTokenMdl {
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal.selectTokenMdl .modal-dialog {
  max-width: calc(100% - 40px);
  width: 420px;
  margin-left: auto;
  margin-right: auto;
}
.modal.selectTokenMdl .modal-dialog .modal-content {
  background-color: #006F76;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  border: none;
  box-shadow: 0px 0px 40px 0px #000000;
  color: #000000;
  position: relative;
}
.modal.selectTokenMdl .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  bottom: -6px;
  right: -4px;
  background-image: url("../images/farming-box-left-frame.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 0;
  transform: scaleX(-1);
  width: 54px;
  height: 94px;
  background-position: bottom right;
}
.modal.selectTokenMdl .modal-dialog .modal-content::after {
  content: "";
  position: absolute;
  top: -10px;
  background-image: url("../images/farming-box-right-frame.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 0;
  transform: scaleX(-1);
  left: -5px;
  width: 74px;
  height: 184px;
  background-position: top left;
}
.modal.selectTokenMdl .modal-footer {
  text-align: center;
  padding: 0px;
}
.modal.selectTokenMdl .modal-footer button {
  color: #ffffff;
  margin: 0 auto;
  padding: 15px;
  font-size: 16px;
  border-radius: 0px 0px 6px 6px;
  width: 100%;
  outline: none;
  border: none;
  background-color: #004848;
}
.modal.selectTokenMdl .modal-footer button svg {
  margin-right: 6px;
  position: relative;
  top: -2px;
  display: inline-block;
}
.modal.selectTokenMdl .inputBox {
  margin: 0 0 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.modal.selectTokenMdl .inputBox input {
  width: 100%;
  font-size: 18px;
  color: #000000;
  padding: 12px;
  line-height: 1;
  border-radius: 10px;
  border: 1px solid #c2c2c2;
  outline: none;
}
.modal.selectTokenMdl .modal-body {
  padding: 0px;
}
.modal.selectTokenMdl .modal-header {
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
}
.modal.selectTokenMdl .modal-header .backIcon {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}
.modal.selectTokenMdl .modal-header .backIcon svg {
  position: relative;
  top: -2px;
}
.modal.selectTokenMdl .listViewSel {
  padding: 0px 20px 15px;
  margin: 0px 0px 0px;
  list-style: none;
  display: block;
  border-bottom: 1px solid #005658;
}
.modal.selectTokenMdl .listViewSel li {
  background-color: #005e64;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-right: 8px;
  padding: 5px 6px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}
.modal.selectTokenMdl .listViewSel li.active {
  background-color: #003e42;
  border-color: #01ffff;
}
.modal.selectTokenMdl .listViewSel li img {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 6px;
}
.modal.selectTokenMdl .gridViewSel {
  padding: 0px 0px 20px;
  margin: 0px;
  list-style: none;
  display: block;
}
.modal.selectTokenMdl .gridViewSel li {
  background-color: transparent;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.1s ease-in-out;
}
.modal.selectTokenMdl .gridViewSel li:hover {
  background-color: #005e64;
}
.modal.selectTokenMdl .gridViewSel li .inrBx {
  display: flex;
  align-items: center;
}
.modal.selectTokenMdl .gridViewSel li p {
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
}
.modal.selectTokenMdl .gridViewSel li p span {
  display: block;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
}
.modal.selectTokenMdl .gridViewSel li img {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 12px;
}
.modal.selectTokenMdl .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.modal.selectTokenMdl .nav li {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
  background-color: #dddddd;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
}
.modal.selectTokenMdl .nav li a {
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  color: #000000;
  font-size: 20px;
}
.modal.selectTokenMdl .nav li a:hover {
  opacity: 0.8;
}
.modal.selectTokenMdl .nav li a.active {
  background-color: #afafaf;
}
.modal.selectTokenMdl .nav li:nth-child(2) {
  border-radius: 0px 10px 10px 0px;
}
.modal.selectTokenMdl .tab-content {
  padding: 0px 20px;
}
.modal.selectTokenMdl .tab-content li {
  background-color: transparent;
  padding: 10px 0px;
  font-size: 16px;
  color: #000000;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.1s ease-in-out;
  border-radius: 10px;
}
.modal.selectTokenMdl .tab-content li:hover {
  background-color: transparent;
}
.modal.selectTokenMdl .tab-content li .inrBx {
  display: flex;
  align-items: center;
}
.modal.selectTokenMdl .tab-content li .switchOnOff {
  position: relative;
  text-align: right;
}
.modal.selectTokenMdl .tab-content li .switchOnOff input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 0;
}
.modal.selectTokenMdl .tab-content li .switchOnOff span::before {
  content: "On";
}
.modal.selectTokenMdl .tab-content li .switchOnOff input:checked + span::before {
  content: "Off";
}
.modal.selectTokenMdl .tab-content li p {
  margin: 0px;
  font-weight: bold;
  font-size: 16px;
}
.modal.selectTokenMdl .tab-content li p span {
  display: block;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
}
.modal.selectTokenMdl .tab-content li p span svg {
  padding: 0px;
  width: 12px;
  display: inline-block;
  height: auto;
  position: relative;
  top: -1px;
}
.modal.selectTokenMdl .tab-content li p span .btn-group {
  margin: 0px 0px 0px 10px;
}
.modal.selectTokenMdl .tab-content li p span .btn-group button {
  padding: 0px;
  height: auto;
  margin: 0;
  background: none;
  border: none;
}
.modal.selectTokenMdl .tab-content li p span .dropdown-menu li {
  background-color: transparent !important;
  padding: 5px 15px;
}
.modal.selectTokenMdl .tab-content li p span .dropdown-menu li a {
  padding: 0px;
  background-color: transparent !important;
  color: #000000;
}
.modal.selectTokenMdl .tab-content li p span .dropdown-menu li a:hover {
  text-decoration: underline;
}
.modal.selectTokenMdl .tab-content li p span .dropdown-menu li:nth-child(1) {
  padding-top: 5px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dddddd;
}
.modal.selectTokenMdl .tab-content li p span .dropdown-menu li:nth-child(1) a {
  color: #777777;
}
.modal.selectTokenMdl .tab-content li img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 12px;
}
.modal.previewModalBox {
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal.previewModalBox .modal-dialog {
  max-width: calc(100% - 40px);
  width: 420px;
  margin-left: auto;
  margin-right: auto;
}
.modal.previewModalBox .modal-dialog .modal-content {
  background-color: #006F76;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  border: none;
  box-shadow: 0px 0px 40px 0px #000000;
  color: #000000;
  position: relative;
}
.modal.previewModalBox .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  bottom: -6px;
  right: -4px;
  background-image: url("../images/farming-box-left-frame.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 0;
  transform: scaleX(-1);
  width: 54px;
  height: 94px;
  background-position: bottom right;
}
.modal.previewModalBox .modal-dialog .modal-content::after {
  content: "";
  position: absolute;
  top: -10px;
  background-image: url("../images/farming-box-right-frame.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 0;
  transform: scaleX(-1);
  left: -5px;
  width: 74px;
  height: 184px;
  background-position: top left;
}
.modal.previewModalBox .modal-footer {
  text-align: center;
  padding: 0px;
}
.modal.previewModalBox .modal-body {
  padding: 20px;
  position: relative;
  z-index: 1;
}
.modal.previewModalBox .modal-header {
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  border-bottom: 1px solid #26848a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: static;
  z-index: 2;
}
.modal.previewModalBox .modal-header h5 {
  order: 1;
  position: relative;
  z-index: 2;
}
.modal.previewModalBox .modal-header .btn-group {
  order: 0;
  cursor: pointer;
  position: static;
}
.modal.previewModalBox .modal-header .btn-group .dropdown-toggle {
  padding: 0px;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border: none;
  position: static;
}
.modal.previewModalBox .modal-header .btn-group .dropdown-toggle svg {
  stroke: #ffffff;
}
.modal.previewModalBox .modal-header .btn-group .settingDownToggle {
  min-width: 256px;
  top: 55px;
  left: 20px;
  position: absolute;
  border-radius: 5px;
  border: 1px solid #009398;
  padding: 0px;
  box-shadow: 0px 20px 50px -30px #000000;
  background-color: #001f20;
  color: #ffffff;
  transition: all 0.3s ease-in;
  z-index: 3;
  transform: scale(0);
  transform-origin: top left;
  cursor: default;
}
.modal.previewModalBox .modal-header .btn-group.show .dropdown-toggle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.769);
  z-index: -1;
}
.modal.previewModalBox .modal-header .btn-group.show .settingDownToggle {
  transform: scale(1);
}
.modal.previewModalBox .modal-header svg {
  width: 25px;
  height: auto;
}
.modal.previewModalBox .modal-header .btn-close {
  position: relative;
  margin: 0px;
  order: 2;
  filter: invert(1);
  opacity: 1;
  z-index: 2;
}
.modal.previewModalBox .modal-header .settingDownToggle .css-bbq5bh svg {
  display: inline-block;
  width: 18px;
  height: auto;
}
.modal.previewModalBox .modal-header .settingDownToggle .css-bbq5bh svg + div {
  min-width: -moz-max-content;
  min-width: max-content;
}
.modal.previewModalBox .modal-header .settingDownToggle .css-bbq5bh svg + div > div {
  padding: 4px 6px;
  font-size: 14px;
  border-radius: 2px;
}
.modal.previewModalBox .modal-header .settingDownToggle h3 {
  font-size: 18px;
  margin: 0px 0px 10px;
  font-weight: 500;
  color: #01b1b1;
}
.modal.previewModalBox .modal-header .settingDownToggle ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.modal.previewModalBox .modal-header .settingDownToggle ul li {
  margin: 0px 0px 20px;
}
.modal.previewModalBox .modal-header .settingDownToggle ul p {
  margin: 0px 0px 10px;
  font-size: 16px;
}
.modal.previewModalBox .modal-header .settingDownToggle ul p svg {
  fill: #ffffff;
  margin-left: 10px;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.toleranceBox button {
  flex: 1 1;
  border: none;
  margin-right: 10px;
  border-radius: 5px;
  line-height: 1;
  padding: 6px 8px;
  background-color: #01ffff;
  color: #000000;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.toleranceBox .inputBox {
  position: relative;
  flex: auto;
  max-width: 220px;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.toleranceBox .inputBox::after {
  content: "%";
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  color: #000000;
  font-weight: bold;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.toleranceBox .inputBox input {
  border: none;
  padding: 0 4px;
  padding-right: 25px;
  width: 100%;
  text-align: right;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  line-height: 1.66;
  color: #000000;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.deadlineBox {
  justify-content: flex-start;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.deadlineBox .inputBox {
  position: relative;
  flex: 1 1;
  max-width: 80px;
  margin-right: 10px;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.deadlineBox .inputBox input {
  border: none;
  padding: 0 4px;
  padding-right: 15px;
  width: 100%;
  text-align: right;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  line-height: 1.66;
  color: #000000;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.rowBxinte .form-check {
  padding: 0px !important;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.rowBxinte .form-check input {
  filter: brightness(100%);
  box-shadow: none !important;
  border: 1px solid transparent !important;
  background-color: #006f76;
  outline: none !important;
  margin-left: 0 !important;
}
.modal.previewModalBox .modal-header .settingDownToggle .rowBox.rowBxinte .form-check input:checked {
  border: 1px solid #ffffff !important;
}
.modal.previewModalBox .topheadTtl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.modal.previewModalBox .topheadTtl h2 {
  margin: 0px;
  display: flex;
  align-items: center;
}
.modal.previewModalBox .topheadTtl img {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50px;
  border: 1px solid #2b8592;
  position: relative;
}
.modal.previewModalBox .topheadTtl img + img {
  left: -8px;
}
.modal.previewModalBox .topheadTtl strong {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
}
.modal.previewModalBox .topheadTtl span {
  color: #01ffff;
  font-size: 16px;
}
.modal.previewModalBox .topheadTtl span::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #01ffff;
  display: inline-block;
  border-radius: 50px;
  margin-left: 5px;
}
.modal.previewModalBox .feeTierTable {
  padding: 20px;
  border-radius: 5px;
  background-color: #003132;
}
.modal.previewModalBox .feeTierTable .rowBx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.modal.previewModalBox .feeTierTable .rowBx strong {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
}
.modal.previewModalBox .feeTierTable .rowBx strong img {
  width: 28px;
  height: 28px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50px;
  border: 1px solid #003132;
  position: relative;
  margin-right: 8px;
}
.modal.previewModalBox .feeTierTable .rowBx span {
  color: #ffffff;
  font-size: 18px;
}
.modal.previewModalBox .feeTierTable .rowBx:nth-last-child(1) {
  border-top: 1px solid #00979c;
  padding: 10px 0px 0px;
  margin: 15px 0px 0px;
}
.modal.previewModalBox .feeTierTable .rowBx:nth-last-child(1) strong {
  font-size: 20px;
}
.modal.previewModalBox .selectedRangeBox {
  margin-top: 15px;
}
.modal.previewModalBox .selectedRangeBox ul {
  padding: 0px;
  margin: 0px 0 15px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal.previewModalBox .selectedRangeBox ul h2 {
  color: #ffffff;
  font-size: 18px;
  margin: 0px;
}
.modal.previewModalBox .selectedRangeBox ul .tabmenu {
  display: flex;
  align-items: center;
  border: 1px solid #003132;
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  overflow: hidden;
}
.modal.previewModalBox .selectedRangeBox ul .tabmenu li {
  padding: 5px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
}
.modal.previewModalBox .selectedRangeBox ul .tabmenu li.react-tabs__tab--selected {
  background-color: #003132;
}
.modal.previewModalBox .selectedRangeBox .react-tabs__tab-panel .row .infoBox {
  background-color: #003132;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  padding: 15px;
}
.modal.previewModalBox .selectedRangeBox .react-tabs__tab-panel .row .infoBox strong {
  font-weight: 400;
  line-height: 1;
  font-size: 16px;
  display: block;
  margin: 0 0 3px;
  color: #4ab4bb;
}
.modal.previewModalBox .selectedRangeBox .react-tabs__tab-panel .row .infoBox h3 {
  font-size: 20px;
  margin: 0 0 3px;
}
.modal.previewModalBox .selectedRangeBox .react-tabs__tab-panel .row .infoBox p {
  font-size: 14px;
  margin: 10px 0 0;
  line-height: 1.1;
  opacity: 0.6;
}
.modal.previewModalBox .selectedRangeBox .react-tabs__tab-panel .addBtn {
  width: 100%;
  background-color: #003132;
  color: #ffffff;
  line-height: 1;
  border-radius: 50px;
  padding: 15px 10px;
  font-weight: 500;
  font-size: 19px;
  border: 1px solid #00979c;
}
.modal.previewModalBox .selectedRangeBox .react-tabs__tab-panel .addBtn:hover {
  background-color: #006364;
}
.modal.previewModalBox .selectedRangeBox.removeLiqBx h2 {
  color: #ffffff;
  font-size: 22px;
}
.modal.stakeModalPopup input[type=number] {
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  background-color: #004a4e;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}
.modal.stakeModalPopup .modal-dialog .modal-content {
  color: #ffffff;
}
.modal.stakeModalPopup .stakeModalBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 25px;
  max-width: -moz-max-content;
  max-width: max-content;
}
.modal.stakeModalPopup .stakeModalBtn button {
  color: aqua;
  border: none;
  font-size: 18px;
  padding: 10px;
  position: relative;
  min-width: 115px;
  margin: 0 10px;
  line-height: 1.1;
}
.modal.stakeModalPopup .stakeModalBtn button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-image: url("../images/poolbox/poolboxtopline.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
}
.modal.stakeModalPopup .stakeModalBtn button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-image: url("../images/poolbox/poolboxbottomline.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;
}
.modal.stakeModalPopup .tokenDiv {
  font-size: 16px;
}
.modal.stakeModalPopup .tokenDiv span {
  margin-left: 6px;
}
.modal.stakeModalPopup hr {
  margin: 10px 0px;
}
.modal.stakeModalPopup p {
  font-size: 16px;
}
.modal.stakeModalPopup p b {
  color: #08fdff;
  font-weight: 500;
}

.yourPositionBox {
  padding: 0px;
  border: 1px solid #006f76;
  border-radius: 5px;
  overflow: hidden;
}
.yourPositionBox .headTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #006f76;
  background-color: #002324;
}
.yourPositionBox .headTitle h2 {
  margin: 0px;
  font-size: 18px;
}
.yourPositionBox .headTitle a {
  color: #ffffff;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}
.yourPositionBox .rowBoxItems {
  border-bottom: 1px solid #1f4747;
}
.yourPositionBox .rowBoxItems a {
  padding: 20px;
  display: block;
  text-decoration: none;
}
.yourPositionBox .topheadTtl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.yourPositionBox .topheadTtl:nth-last-child(1) {
  border: none;
}
.yourPositionBox .topheadTtl h2 {
  margin: 0px;
  display: flex;
  align-items: center;
}
.yourPositionBox .topheadTtl img {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50px;
  border: 1px solid #2b8592;
  position: relative;
}
.yourPositionBox .topheadTtl img + img {
  left: -8px;
}
.yourPositionBox .topheadTtl strong {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
}
.yourPositionBox .topheadTtl span {
  color: #01ffff;
  font-size: 16px;
}
.yourPositionBox .topheadTtl span::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #01ffff;
  display: inline-block;
  border-radius: 50px;
  margin-left: 5px;
}
.yourPositionBox p {
  color: #ffffff;
  font-size: 14px;
  margin: 5px 0px 0px;
}
.yourPositionBox p span {
  display: inline-block;
  color: #00FFFF;
  margin: 0 8px;
}
.yourPositionBox p svg {
  fill: #00FFFF;
  width: 25px;
  height: auto;
  display: inline-block;
  margin: 0 8px;
}

.nftLiquidity {
  padding: 20px 0px;
}
.nftLiquidity .topheadTtl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.nftLiquidity .topheadTtl h2 {
  margin: 0px;
  display: flex;
  align-items: center;
}
.nftLiquidity .topheadTtl img {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50px;
  position: relative;
}
.nftLiquidity .topheadTtl img + img {
  left: -8px;
}
.nftLiquidity .topheadTtl strong {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
}
.nftLiquidity .topheadTtl b {
  background-color: #005658;
  display: inline-block;
  padding: 5px;
  font-size: 14px;
  border-radius: 3px;
  margin: 0px 10px;
}
.nftLiquidity .topheadTtl span {
  color: #01ffff;
  font-size: 16px;
}
.nftLiquidity .topheadTtl span::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #01ffff;
  display: inline-block;
  border-radius: 50px;
  margin-left: 5px;
}
.nftLiquidity .topheadTtl .btnGrp {
  display: flex;
  align-items: center;
}
.nftLiquidity .topheadTtl .btnGrp button {
  background-color: #003a3b;
  padding: 4px 6px;
  border-radius: 2px;
  color: #08d5c6;
}
.nftLiquidity .topheadTtl .btnGrp button:nth-last-child(1) {
  margin-left: 10px;
  background-color: #08d5c6;
  color: #000000;
}
.nftLiquidity .topheadTtl .btnGrp button:hover {
  opacity: 0.7;
}
.nftLiquidity .innerbox {
  background-color: #003a3b;
  padding: 30px 20px;
  height: 100%;
  border-radius: 5px;
}
.nftLiquidity .innerbox .innerboxImg {
  border: 1px solid #006e74;
  height: 100%;
  min-height: 200px;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 5px;
}
.nftLiquidity .liquidityBox {
  margin-bottom: 20px;
}
.nftLiquidity .liquidityBox h4 {
  font-size: 20px;
  font-weight: 400;
}
.nftLiquidity .liquidityBox h2 {
  font-size: 36px;
  font-weight: 500;
}
.nftLiquidity .liquidityTable {
  border: 1px solid #006e74;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.nftLiquidity .liquidityTable:nth-last-child(1) {
  margin-bottom: 0px;
}
.nftLiquidity .liquidityTable .rowBoxTbl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.nftLiquidity .liquidityTable .rowBoxTbl:nth-last-child(1) {
  margin-bottom: 0px;
}
.nftLiquidity .liquidityTable .rowBoxTbl .imgBx {
  display: flex;
  align-items: center;
  margin: 0px;
}
.nftLiquidity .liquidityTable .rowBoxTbl .imgBx img {
  width: 25px;
  height: 25%;
  margin-right: 8px;
  border-radius: 50px;
  -o-object-fit: contain;
     object-fit: contain;
  height: auto;
}
.nftLiquidity .liquidityTable .rowBoxTbl .imgBx strong {
  font-size: 16px;
  font-weight: 400;
}
.nftLiquidity .liquidityTable .rowBoxTbl .rightBx strong {
  font-size: 16px;
  font-weight: 400;
}
.nftLiquidity .liquidityTable .rowBoxTbl .rightBx span {
  font-size: 14px;
  background-color: #006e74;
  padding: 3px 6px;
  display: inline-block;
  margin-left: 8px;
  border-radius: 2px;
  line-height: 1;
}
.nftLiquidity .priceRangeBox {
  margin-top: 30px;
}
.nftLiquidity .priceRangeBox ul {
  padding: 0px;
  margin: 0px 0 15px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nftLiquidity .priceRangeBox ul h2 {
  color: #ffffff;
  font-size: 18px;
  margin: 0px;
}
.nftLiquidity .priceRangeBox ul h2 span {
  color: #01ffff;
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
}
.nftLiquidity .priceRangeBox ul h2 span::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #01ffff;
  display: inline-block;
  border-radius: 50px;
  margin-left: 5px;
}
.nftLiquidity .priceRangeBox ul .tabmenu {
  display: flex;
  align-items: center;
  border: 1px solid #003132;
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  overflow: hidden;
}
.nftLiquidity .priceRangeBox ul .tabmenu li {
  padding: 5px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  font-size: 14px;
  font-weight: 400;
}
.nftLiquidity .priceRangeBox ul .tabmenu li.react-tabs__tab--selected {
  background-color: #003132;
}
.nftLiquidity .priceRangeBox .react-tabs__tab-panel .row .infoBox {
  background-color: #003132;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  padding: 15px;
}
.nftLiquidity .priceRangeBox .react-tabs__tab-panel .row .infoBox strong {
  font-weight: 400;
  line-height: 1;
  font-size: 16px;
  display: block;
  margin: 0 0 3px;
  color: #4ab4bb;
}
.nftLiquidity .priceRangeBox .react-tabs__tab-panel .row .infoBox h3 {
  font-size: 20px;
  margin: 0 0 3px;
}
.nftLiquidity .priceRangeBox .react-tabs__tab-panel .row .infoBox p {
  font-size: 14px;
  margin: 10px 0 0;
  line-height: 1.1;
  opacity: 0.6;
}
.nftLiquidity .priceRangeBox .react-tabs__tab-panel .addBtn {
  width: 100%;
  background-color: #003132;
  color: #ffffff;
  line-height: 1;
  border-radius: 50px;
  padding: 15px 10px;
  font-weight: 500;
  font-size: 19px;
  border: 1px solid #00979c;
}
.nftLiquidity .priceRangeBox .react-tabs__tab-panel .addBtn:hover {
  background-color: #006364;
}

.removeLiqBx .enterPercentBtn {
  width: 100%;
  background-color: #003132;
  color: #ffffff;
  line-height: 1;
  border-radius: 50px;
  padding: 15px 10px;
  font-weight: 500;
  font-size: 19px;
  border: 1px solid #00979c;
}
.removeLiqBx .enterPercentBtn:hover {
  background-color: #006364;
}
.removeLiqBx .amountBox {
  padding: 20px;
  border-radius: 5px;
  background-color: #003132;
  color: #ffffff;
  margin-bottom: 20px;
}
.removeLiqBx .amountBox h2 {
  margin: 0 0 10px;
  color: #ffffff;
  font-size: 22px;
}
.removeLiqBx .amountBox .countBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.removeLiqBx .amountBox .countBox strong {
  font-size: 32px;
  font-weight: 400;
}
.removeLiqBx .amountBox .countBox ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.removeLiqBx .amountBox .countBox ul li {
  margin-left: 10px;
  background-color: #006f76;
  padding: 4px 10px;
  font-size: 15px;
  border-radius: 3px;
  cursor: pointer;
}
.removeLiqBx .amountBox .form-range::-webkit-slider-thumb {
  background-color: #006f76;
  border-radius: 1.5rem;
  height: 1.5rem;
  margin-top: -0.5rem;
  width: 1.5rem;
  outline: 2px solid #ffffff !important;
  box-shadow: none !important;
}
.removeLiqBx .topheadTtl {
  margin-bottom: 10px !important;
}
.removeLiqBx .topheadTtl:nth-last-child(1) {
  margin-bottom: 0px !important;
}
.removeLiqBx .topheadTtl span::after {
  display: none !important;
}
.removeLiqBx .topheadTtl img {
  width: 22px !important;
  height: 22px !important;
  margin-left: 7px;
}
.removeLiqBx .topheadTtl .form-check {
  padding: 0px !important;
}
.removeLiqBx .topheadTtl .form-check input {
  filter: brightness(100%);
  box-shadow: none !important;
  border: 1px solid transparent !important;
  background-color: #006f76;
  outline: none !important;
  margin-left: 0 !important;
}
.removeLiqBx .topheadTtl .form-check input:checked {
  border: 1px solid #ffffff !important;
}

.returnSwapbox {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}
.returnSwapbox h2 {
  color: #02ffff;
  font-size: 30px;
  margin-bottom: 5px;
}
.returnSwapbox p {
  color: #ffffff;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 400;
}
.returnSwapbox .btnBox {
  background: url(../images/farming-harvest-btn.svg);
  padding: 8px;
  background-repeat: no-repeat;
  background-origin: padding-box;
  background-size: 100% 100%;
  position: relative;
  min-width: 110px;
  text-align: center;
}
.returnSwapbox button {
  font-size: 14px;
  display: block !important;
  width: 100%;
}
.returnSwapbox .returnInrBox {
  border: 1px solid #006365;
  padding: 15px;
  border-radius: 5px;
  text-align: left;
}
.returnSwapbox .returnInrBox p {
  opacity: 1;
  color: #bababa;
}
.returnSwapbox .returnInrBox p strong {
  display: block;
  color: #ffffff;
  margin-top: 3px;
}

/* media start */
@media (max-width: 1680px) {
  .dashboard {
    padding: 4vw;
  }
  .dashboard .oc_row .oc_col6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 25px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft {
    max-width: 550px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .boxline {
    margin-top: 30px;
    max-width: 415px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .boxline img {
    height: 100%;
    width: 100%;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight {
    max-width: 550px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline {
    margin-top: 65px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline img {
    height: 100%;
    width: 100%;
  }
  .dashboard .oc_row .circleBox .infobox .Typewriter {
    flex-wrap: wrap;
    max-width: 500px;
  }
  .dashboard .oc_row .boxBottomLeft {
    max-width: 350px;
  }
  .dashboard .oc_row .boxBottomRight {
    max-width: 300px;
  }
  .swap .swap-container .button-1 {
    max-width: 300px;
  }
  .swap .swap-container .button-1 svg {
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 svg {
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 label {
    margin-bottom: 45px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-2 {
    max-width: 80px;
    position: relative;
    width: 100%;
    display: block;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 svg {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .main-box {
    width: 450px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 label {
    margin-bottom: 35px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button {
    margin-top: 40px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button span {
    font-size: 25px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button .bg-button {
    max-width: 420px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button .bg-button svg {
    width: 100%;
    height: 100%;
  }
  .nfts .nfts-container .search-box-content .search-bar label input[type=search] {
    font-size: 22px;
  }
  .nfts .nfts-container .search-box-content .search-bar label::after {
    top: -18px;
    bottom: 0;
    width: 360px;
  }
  .nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp ul li {
    padding: 10px 24px;
    font-size: 16px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-sub-input-box {
    height: 90px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box {
    display: flex;
    justify-content: space-between;
    margin: 0 10px 0 10px;
    align-items: center;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-1 {
    width: 65px;
    height: 100%;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-1 svg {
    width: 100%;
    height: 100%;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 span {
    display: block;
    text-align: end;
    color: #01FFFF;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-1 {
    font-size: 29px;
    margin-bottom: 7px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-2 {
    font-size: 17px;
  }
  .gridlistview .farming-container .search-box-content .search-bar label input[type=search] {
    font-size: 22px;
  }
  .gridlistview .farming-container .search-box-content .search-bar label::after {
    top: -18px;
    bottom: 0;
  }
  .gridlistview .farming-container .farming-button-area .empty-area {
    width: 400px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn {
    max-width: 55px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn {
    max-width: 55px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
    font-size: 16px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 24px;
    font-size: 16px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
    padding: 10px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
    font-size: 16px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    max-height: 100px;
    width: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    font-size: 22px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 14px;
    display: flex;
    width: 100%;
    flex: 0 0 70%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 55%;
    margin-top: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 20px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 20px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
    display: flex;
    align-items: center;
    margin-inline-start: 40px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    justify-content: space-between;
    margin-top: 20px;
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 20px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 14px;
    line-height: 1.5rem;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 20px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 {
    display: table-row;
    width: 100%;
    flex: 0 0 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button {
    background: transparent;
    color: aqua;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 400px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 22px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
    position: relative;
    transition: all 0.5s;
    mix-blend-mode: hard-light;
    z-index: 99;
    max-width: 506px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1.height-slider {
    max-height: 400px;
  }
  .nfts .nfts-container .nfts-content-area {
    margin: 0px auto 0;
  }
}
@media (max-width: 1400px) {
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData img {
    padding-left: 0;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData span {
    right: 8px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .boxline {
    margin-top: 30px;
    max-width: 334px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .svgCirBox {
    width: auto;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .svgCirBox .mainbox {
    max-width: 92px;
    width: 100%;
    height: auto;
    max-height: 92px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .svgCirBox .mainbox img {
    width: 100%;
    height: 100%;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .svgCirBox .mainbox svg.circular-chart {
    width: 100%;
    height: 100%;
    max-width: 92px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .svgCirBox span {
    font-size: 16px;
  }
  .dashboard .oc_row .circleBox {
    margin-bottom: 3vw;
    max-height: 285px;
  }
  .dashboard .oc_row .boxBottomLeft {
    max-width: 312px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox {
    padding: 15px 42px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr p {
    font-size: 15px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr .progress {
    height: 21px;
  }
  .dashboard .oc_row .boxBottomLeft .oc_row {
    flex-wrap: nowrap;
  }
  .dashboard .oc_row .boxBottomLeft .oc_row .innerbox {
    height: 20vw;
    max-height: 115px;
    min-height: 100px;
    margin-bottom: 3.5vw;
    padding-inline: 5px;
  }
  .dashboard .oc_row .boxBottomLeft .oc_row .innerbox h2 {
    font-size: 18px;
  }
  .dashboard .oc_row .boxBottomRight {
    max-width: 310px;
  }
  .dashboard .oc_row .boxBottomRight .progressbox {
    padding: 15px 40px;
    width: 310px;
    height: 320px;
  }
  .dashboard .oc_row .boxBottomRight .progressbox .progressboxinr .progress {
    height: 21px;
  }
  .dashboard .oc_row .boxBottomRight::after {
    bottom: -11px;
    font-size: 21px;
  }
  .swap .swap-container .button-1 {
    max-width: 250px;
  }
  .swap .swap-container .button-1 svg {
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 500px;
    display: block;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 svg {
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .main-box {
    width: 400px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 label {
    margin-bottom: 35px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-2 {
    max-width: 80px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 {
    width: 100%;
    max-width: 500px;
    margin-inline: auto;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 svg {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .main-box {
    width: 400px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 label {
    margin-bottom: 35px;
  }
  .swap .swap-container .swap-main-box .input-boxes .swapNetwork {
    max-width: 500px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button {
    margin-top: 40px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button span {
    font-size: 20px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button .bg-button {
    max-width: 350px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button .bg-button svg {
    width: 100%;
    height: 100%;
  }
  .nfts .nfts-container .search-box-content .search-bar label input[type=search] {
    font-size: 19px;
  }
  .nfts .nfts-container .search-box-content .search-bar label::after {
    top: -21px;
    bottom: 0;
    width: 100%;
  }
  .nfts .nfts-container .nfts-button .button-1 {
    max-width: 250px;
  }
  .nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp ul li {
    padding: 10px 17px;
    font-size: 15px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-sub-input-box {
    height: 99px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box {
    display: flex;
    justify-content: space-between;
    margin: 0 10px 0 10px;
    align-items: center;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-1 {
    width: 65px;
    height: 100%;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-1 svg {
    width: 100%;
    height: 100%;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 span {
    display: block;
    text-align: end;
    color: #01FFFF;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-1 {
    font-size: 29px;
    margin-bottom: 7px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-2 {
    font-size: 17px;
  }
  .gridlistview .farming-container .search-box-content .search-bar label input[type=search] {
    font-size: 19px;
  }
  .gridlistview .farming-container .search-box-content .search-bar label::after {
    top: -21px;
    bottom: 0;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 {
    max-width: 250px;
  }
  .gridlistview .farming-container .farming-button-area .empty-area {
    width: 242px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group {
    width: 120px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn {
    max-width: 55px;
    margin-right: 0px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn {
    max-width: 55px;
    margin-right: 0px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
    font-size: 16px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 24px;
    font-size: 14px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
    padding: 10px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
    font-size: 16px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    max-height: 100px;
    width: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    flex: 0 0 30%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 14px;
    display: flex;
    width: 100%;
    flex: 0 0 70%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 70%;
    margin-top: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 20px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 20px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
    display: flex;
    align-items: center;
    margin-inline-start: 40px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    justify-content: space-between;
    margin-top: 20px;
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 20px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 14px;
    line-height: 1.5rem;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 20px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 {
    display: table-row;
    width: 100%;
    flex: 0 0 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button {
    background: transparent;
    color: aqua;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 400px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 22px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
    position: relative;
    transition: all 0.5s;
    mix-blend-mode: hard-light;
    z-index: 99;
    max-width: 506px;
  }
  .gridlistview:not(.listviewmode) .farming-container .farming-content-area .farming-box-1 {
    flex-basis: calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
  .gridlistview .farming-container .search-box-content .empty-space,
  .nfts .nfts-container .search-box-content .empty-space {
    display: none;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border {
    width: calc(100% - 120px);
  }
}
@media (max-width: 1200px) {
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData span {
    right: -7px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .boxline {
    max-width: 306px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData span {
    left: 16px;
    font-size: 19px;
  }
  .dashboard .oc_row .boxBottomRight {
    max-width: 300px;
  }
  .dashboard .oc_row .boxBottomRight .border_line img {
    left: -120px;
    max-width: 370px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox {
    padding: 15px 43px;
    width: 304px;
    height: 290px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr {
    margin-bottom: 9px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr .progress {
    height: 18px;
  }
  .dashboard .oc_row .boxBottomRight::after {
    bottom: 0px;
  }
  .swap .swap-container .button-1 {
    max-width: 210px;
  }
  .swap .swap-container .button-1 span {
    font-size: 15px;
  }
  .swap .swap-container .button-1 svg {
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 470px;
    display: block;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 svg {
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .main-box {
    width: 360px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 label {
    margin-bottom: 30px;
    font-size: 22px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 input[type=text] {
    font-size: 22px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .bg-logo {
    width: 33px;
    height: 33px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-2 {
    max-width: 80px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-2 svg {
    width: 60px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 {
    width: 100%;
    max-width: 470px;
    margin-inline: auto;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 svg {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .main-box {
    width: 360px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 label {
    margin-bottom: 30px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 input[type=text] {
    font-size: 22px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .bg-logo {
    width: 33px;
    height: 33px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button {
    margin-top: 40px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button span {
    font-size: 20px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button .bg-button {
    max-width: 350px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button .bg-button svg {
    width: 100%;
    height: 100%;
  }
  .nfts .nfts-container .search-box-content .search-bar label input[type=search] {
    font-size: 18px;
  }
  .nfts .nfts-container .nfts-button .button-1 {
    max-width: 210px;
  }
  .nfts .nfts-container .nfts-button .button-1 span {
    font-size: 15px;
  }
  .nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp ul li {
    padding: 10px 14px;
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::after {
    left: -4px;
    width: 55px;
    height: 135px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    font-size: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 15px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    margin-top: 25px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 17px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    margin-top: 25px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 19px;
    margin-block: 7px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 {
    margin-block: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
    max-width: 210px;
  }
  .gridlistview .farming-container .search-box-content .search-bar label input[type=search] {
    font-size: 18px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 {
    max-width: 210px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 span {
    font-size: 15px;
  }
  .gridlistview .farming-container .farming-button-area {
    justify-content: space-between;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn {
    max-width: 50px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn {
    max-width: 50px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp button {
    padding: 8px 19px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 14px;
    font-size: 14px;
    text-align: center;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 16px;
    font-size: 14px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
    padding: 10px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
    font-size: 15px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    max-height: 84px;
    width: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    flex: 0 0 30%;
    font-size: 17px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
    flex: 0 0 70%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
    flex: 0 0 73%;
    margin-top: 10px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 16px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 16px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
    display: flex;
    align-items: center;
    margin-inline-start: 0px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button {
    max-width: 70px;
    height: 100%;
    display: block;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button img {
    width: 100%;
    height: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    justify-content: space-between;
    margin-top: 0px;
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
    line-height: 1.5rem;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 16px;
    margin-block: 4px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button {
    max-width: 330px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 18px;
  }
  .slideBoxMain .slideMainCir {
    flex: 0 0 500px;
    max-width: 500px;
    margin-left: -170px;
  }
  .slideBoxMain .slideMainCir svg {
    max-width: 202px;
    top: calc(50% + 2px);
    left: calc(50% - 58px);
  }
  .slideBoxMain .slideMainPages {
    flex-basis: calc(100% - 350px);
    max-width: calc(100% - 350px);
    padding-right: 20px;
  }
  .slideBoxMain .slideMainCir .slideMainCirInner .swapLogo {
    top: calc(50% + 5px);
    left: calc(50% - 54px);
    width: 60px;
  }
  .middelBox .oc_col12 {
    width: 100%;
    margin-bottom: 30px;
  }
  .circleBox .infobox .Typewriter {
    font-size: 2vw;
  }
  .circleBox {
    max-width: 100vw;
  }
  .dashboard .oc_row .circleBox .infobox .Typewriter {
    max-width: 100%;
  }
  .swap .swap-container button img {
    width: 20px;
  }
}
@media (max-width: 1024px) {
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::after {
    left: -4px;
    width: 55px;
    height: 135px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    font-size: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 15px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    margin-top: 25px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 17px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    margin-top: 25px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 19px;
    margin-block: 7px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 {
    margin-block: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
    max-width: 210px;
  }
  .gridlistview .farming-container .search-box-content .search-bar label input[type=search] {
    font-size: 18px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 {
    max-width: 210px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 span {
    font-size: 15px;
  }
  .gridlistview .farming-container .farming-button-area {
    justify-content: space-between;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn {
    max-width: 50px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn {
    max-width: 50px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp button {
    padding: 8px 19px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 14px;
    font-size: 14px;
    text-align: center;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 16px;
    font-size: 14px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
    padding: 10px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
    font-size: 15px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    max-height: 84px;
    width: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    flex: 0 0 30%;
    font-size: 17px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
    flex: 0 0 70%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
    flex: 0 0 75%;
    margin-top: 10px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 16px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 16px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
    display: flex;
    align-items: center;
    margin-inline-start: 0px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button {
    max-width: 70px;
    height: 100%;
    display: block;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button img {
    width: 100%;
    height: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    justify-content: space-between;
    margin-top: 0px;
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
    line-height: 1.5rem;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 16px;
    margin-block: 4px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 12px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button {
    max-width: 330px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .dashboard .oc_row .oc_col6 {
    padding: 15px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft {
    max-width: 380px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartBox {
    flex: 0 0 100%;
    max-width: 70%;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData {
    flex: 0 0 100%;
    max-width: 90%;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData span {
    right: 0px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .boxline {
    display: none;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight {
    max-width: 380px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row {
    justify-content: end;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData {
    width: 322px;
    position: relative;
    flex: 0 0 75%;
    max-width: 75%;
    display: none;
    height: 133px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData img {
    width: 100%;
    height: 100%;
    position: relative;
    left: -160px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData span {
    display: none;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progrfour {
    flex: 0 0 60%;
    max-width: 59%;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progressCirclebox {
    margin-bottom: 6px;
    padding: 0px 8px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline {
    margin-top: 85px;
  }
  .dashboard .oc_row .circleBox {
    max-height: 330px;
    margin-top: -9vw;
  }
  .dashboard .oc_row .circleBox .infobox .Typewriter {
    font-size: 3vw;
    margin-top: 15px;
  }
  .dashboard .oc_row .oc_col12 {
    display: flex;
  }
  .dashboard .oc_row .oc_mobile .innerbox {
    max-height: 91px;
    min-height: 96px;
    margin-block: 6.5vw;
  }
  .dashboard .oc_row .boxBottomLeft {
    max-width: 350px;
    display: flex;
    flex-direction: column-reverse;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox {
    padding: 15px 0px;
    margin-bottom: 0px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox::before {
    display: none;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox::after {
    display: none;
  }
  .dashboard .oc_row .boxBottomLeft .oc_row {
    display: none;
  }
  .dashboard .oc_row .boxBottomRight {
    max-width: 350px;
    align-items: flex-end;
  }
  .dashboard .oc_row .boxBottomRight .border_line::before {
    display: none;
  }
  .dashboard .oc_row .boxBottomRight .border_line img {
    display: none;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox {
    padding: 15px 0px;
    margin-bottom: 0px;
    width: 355px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox::before {
    display: none;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox::after {
    display: none;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr {
    margin-bottom: 12px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr p {
    text-align: end;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr .progress {
    height: 18px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr .progress span {
    margin-right: 10px;
  }
  .dashboard .oc_row .boxBottomRight::after {
    bottom: 0px;
    display: none;
  }
  .swap {
    position: relative;
  }
  .swap .swap-container .button-1 {
    margin: 0 auto 30px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 {
    max-width: 450px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .main-box {
    width: 345px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 label {
    margin-bottom: 20px;
    font-size: 19px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 input[type=text] {
    font-size: 19px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .token-balance .token-currency .bg-logo {
    width: 23px;
    height: 23px;
    margin-inline-end: 12px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-2 {
    max-width: 60px;
    margin: 0 auto;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 {
    width: 100%;
    max-width: 450px;
    margin-inline: auto;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .main-box {
    width: 345px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 label {
    margin-bottom: 20px;
    font-size: 19px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 input[type=text] {
    font-size: 19px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .token-balance .token-currency .bg-logo {
    width: 23px;
    height: 23px;
    margin-inline-end: 12px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button {
    margin-top: 30px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button span {
    font-size: 15px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button .bg-button {
    max-width: 260px;
  }
  .swap img.swap_frame {
    height: 100%;
    max-width: 980px;
    position: absolute;
    top: 0px;
    z-index: -1;
    -o-object-fit: cover;
       object-fit: cover;
    left: 48.4%;
    transform: translateX(-50%);
  }
  .nfts .nfts-container .scroll-border .scroll {
    overflow: auto;
    max-height: unset;
    padding: 0px 10px;
    margin: 0px;
  }
  .nfts .nfts-container .search-box-content {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .nfts .nfts-container .search-box-content .empty-space {
    width: 100%;
  }
  .nfts .nfts-container .search-box-content .search-bar-box {
    width: 100%;
  }
  .nfts .nfts-container .search-box-content .search-bar-box .search-bar {
    width: 100%;
    justify-content: center;
  }
  .nfts .nfts-container .search-box-content .nfts-button {
    display: block;
    width: 100%;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-sub-input-box {
    height: 80px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-1 {
    width: 50px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-1 {
    font-size: 23px;
    margin-bottom: 7px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-2 {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border {
    margin: 30px auto;
  }
  .gridlistview .farming-container .scroll-border .scroll-shadow {
    display: none;
  }
  .gridlistview .farming-container .scroll-border .scroll {
    max-height: unset;
    margin: 0px;
    padding-inline: 0px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::after {
    left: -4px;
    width: 55px;
    height: 135px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    font-size: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 15px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    margin-top: 17px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 17px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    margin-top: 17px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 19px;
    margin-block: 7px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 {
    margin-block: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
    max-width: 210px;
  }
  .gridlistview .farming-container .search-box-content {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .gridlistview .farming-container .search-box-content .search-bar-box {
    width: 100%;
  }
  .gridlistview .farming-container .search-box-content .search-bar-box .search-bar {
    width: 100%;
    justify-content: center;
  }
  .gridlistview .farming-container .search-box-content .search-bar-box .search-bar label input[type=search] {
    font-size: 18px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button {
    display: block;
    width: 100%;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 {
    max-width: 210px;
    margin-inline: auto;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 span {
    font-size: 15px;
  }
  .gridlistview .farming-container .farming-button-area {
    justify-content: space-around;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn {
    max-width: 50px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn {
    max-width: 50px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp button {
    padding: 8px 19px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 14px;
    font-size: 14px;
    text-align: center;
  }
  .gridlistview .farming-container .farming-button-area .empty-area {
    width: 0px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 16px;
    font-size: 14px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
    padding: 10px;
  }
  .gridlistview .farming-container .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
    font-size: 15px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll {
    padding: 0px;
    margin: 10px 15px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    max-height: 84px;
    width: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    flex: 0 0 30%;
    font-size: 17px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
    flex: 0 0 70%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
    flex: 0 0 73%;
    margin-top: 10px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 16px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 16px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
    display: flex;
    align-items: center;
    margin-inline-start: 0px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button {
    max-width: 70px;
    height: 100%;
    display: block;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button img {
    width: 100%;
    height: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    justify-content: space-between;
    margin-top: 0px;
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    flex: 0 0 50%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
    line-height: 1.5rem;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 16px;
    margin-block: 4px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button {
    max-width: 330px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 18px;
  }
  .slideBoxMain {
    margin: 30px auto 0px;
  }
  .slideBoxMain .slideMainPages {
    flex-basis: calc(100% - 50px);
    max-width: calc(100% - 50px);
    order: 1;
    padding: 0px 10px 290px;
    margin: 0px auto 0px;
    border-top: 7px solid #01ffff;
    border-left: 5px solid #01ffff;
    border-right: 5px solid #01ffff;
    position: relative;
    border-radius: 15px 15px 0px 0px;
  }
  .slideBoxMain .slideMainPages::before {
    content: "";
    position: sticky;
    top: 0px;
    width: 100%;
    height: 30px;
    background-image: linear-gradient(black, transparent);
    display: block;
    z-index: 999;
  }
  .slideBoxMain .slideMainCir {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 48px);
    max-width: calc(100% - 48px);
    margin: 0 auto;
    order: 0;
    padding: 0px;
    z-index: 99999;
    background: #000000;
    width: 100%;
    top: unset;
    height: 290px;
    bottom: 0px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) scaleY(0);
    transform-origin: bottom center;
  }
  .slideBoxMain .slideMainCir.fixed {
    transform: translateX(-50%) scaleY(1);
  }
  .slideBoxMain .slideMainCir::before {
    content: "";
    position: absolute;
    top: -90px;
    left: 50%;
    width: calc(100% - 45px);
    height: 100px;
    background-image: linear-gradient(transparent, #000000);
    z-index: 1;
    transform: translateX(-50%);
  }
  .slideBoxMain .slideMainCir .slideMainCirInner::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 50vw;
    background-color: #01ffff;
    transform-origin: top left;
    transform: rotate(-50deg);
    z-index: -1;
    top: 0;
    left: 0;
  }
  .slideBoxMain .slideMainCir .slideMainCirInner::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 50vw;
    background-color: #01ffff;
    transform-origin: top right;
    transform: rotate(50deg);
    z-index: -1;
    top: 0;
    right: 0;
  }
  .slideBoxMain .slideMainCir .slideMainCirInner .slideText {
    top: 85px;
    left: calc(50% - 50px);
    right: unset;
  }
  .slideBoxMain .slideMainCir .slideMainCirInner .slideSwapCirBack {
    max-width: 400px;
    transform: rotate(4deg);
  }
  .swap .swap-container .swap-main-box {
    padding: 0px;
  }
  .nfts .nfts-container .nfts-button .button-1 {
    margin-left: auto;
    margin-right: auto;
  }
  .nfts .nfts-container .scroll-border .scroll-shadow {
    display: none;
  }
  .gridlistview.listviewmode .farming-container .farming-content-area .farming-box-1 {
    margin: 0 0 15px;
  }
  .hiddenMob {
    display: none !important;
  }
  .slideBoxMain .slideMainCir .slideMainCirInner {
    margin-bottom: 0px;
    padding-top: 40px;
    overflow: hidden;
    height: 290px;
  }
  .slideBoxMain .slideMainCir svg {
    max-width: 180px;
    top: calc(50% + 97px);
    left: calc(50% - 10px);
  }
  .slideBoxMain .slideMainCir .slideMainCirInner .swapLogo {
    top: calc(50% + 97px);
    left: calc(50% - 10px);
    width: 60px;
  }
  .swap .swap-container button {
    margin-right: auto;
  }
  .middelBox .oc_col12 {
    margin-bottom: 0px;
  }
  .modal.newPositionMdl .modal-dialog {
    max-width: calc(100% - 20px);
    width: 550px;
  }
  .modal.newPositionMdl .modal-dialog .modal-header {
    padding: 20px 15px 20px 10px;
  }
  .modal.newPositionMdl .popMaininrBox .col-lg-6 {
    padding: 10px 10px;
  }
  .modal.newPositionMdl .selectPairBox .btn-group:nth-child(1) {
    padding-right: 5px;
  }
  .modal.newPositionMdl .selectPairBox .btn-group:nth-child(2) {
    padding-left: 5px;
  }
  .modal.newPositionMdl .selectPairBox .btn-group button {
    padding: 10px 5px 10px 10px;
    font-size: 14px;
  }
  .modal.newPositionMdl .bestForBox .bstfrbxInr {
    padding: 5px;
  }
  .modal.newPositionMdl .bestForBox {
    margin-top: 15px;
  }
  .modal.newPositionMdl .modal-dialog .modal-header .modal-title {
    font-size: 14px;
    padding-left: 0px;
  }
  .modal.newPositionMdl .setPriceRangeBx p {
    font-size: 16px;
  }
  .modal.newPositionMdl .titileBox {
    text-align: center;
  }
  .nftLiquidity .topheadTtl {
    justify-content: center;
    flex-wrap: wrap;
  }
  .nftLiquidity .topheadTtl h2 {
    margin: 0px 0px 15px;
  }
  .nftLiquidity .col-12.col-lg-6 {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartBox {
    flex: 0 0 100%;
    max-width: 90%;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData {
    flex: 0 0 100%;
    max-width: 90%;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData span {
    right: 0px;
    font-size: 15px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData {
    width: 322px;
    position: relative;
    flex: 0 0 75%;
    max-width: 75%;
    display: none;
    height: 133px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData img {
    width: 100%;
    height: 100%;
    position: relative;
    left: -160px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData span {
    display: none;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progrfour {
    flex: 0 0 70%;
    max-width: 65%;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progressCirclebox {
    margin-bottom: 6px;
    padding: 0px 5px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progressCirclebox span {
    font-size: 14px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline {
    left: 44%;
    margin-top: 100px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline::after {
    font-size: 19px;
  }
  .dashboard .oc_row .circleBox {
    margin-top: -5vw;
  }
  .dashboard .oc_row .circleBox .infobox {
    margin-block: 2.5vw;
  }
  .dashboard .oc_row .circleBox .infobox .Typewriter {
    font-size: 4vw;
  }
  .dashboard .oc_row .oc_mobile {
    margin-top: 4.5vw;
  }
  .dashboard .oc_row .oc_mobile .innerbox {
    max-height: 91px;
    min-height: 96px;
  }
  .dashboard .oc_row .boxBottomLeft {
    max-width: 250px;
  }
  .dashboard .oc_row .boxBottomRight {
    width: 100%;
  }
  .dashboard .oc_row .boxBottomRight .border_line {
    width: 100%;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox {
    width: 100%;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr p {
    text-align: end;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr .progress {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr .progress span {
    margin-right: 10px;
  }
  .swap img.swap_frame {
    top: 41px;
  }
  .nfts img.swap_frame {
    left: 47%;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::after {
    left: -4px;
    width: 55px;
    height: 135px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    font-size: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 15px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    margin-top: 25px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 17px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    margin-top: 25px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 19px;
    margin-block: 7px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 {
    margin-block: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
    max-width: 210px;
  }
  .gridlistview .farming-container .search-box-content {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .gridlistview .farming-container .search-box-content .search-bar-box {
    margin-top: 0px;
  }
  .gridlistview .farming-container .search-box-content .search-bar-box .search-bar {
    width: 100%;
    justify-content: flex-start;
  }
  .gridlistview .farming-container .search-box-content .search-bar-box .search-bar label input[type=search] {
    font-size: 18px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button {
    display: block;
    width: 100%;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 {
    max-width: 210px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 span {
    font-size: 15px;
  }
  .gridlistview .farming-container .farming-button-area {
    justify-content: space-around;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn {
    max-width: 45px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .list-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn {
    max-width: 45px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group .gird-btn button {
    width: 100%;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 14px;
    font-size: 14px;
    text-align: center;
  }
  .gridlistview .farming-container .farming-button-area .empty-area {
    width: 0px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 12px;
    font-size: 14px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
    padding: 10px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
    font-size: 15px;
    padding: 8px 15px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll {
    padding: 0px;
    margin: 10px 15px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    max-height: 75px;
    width: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    flex: 0 0 10%;
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
    flex: 0 0 64%;
    align-items: center;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
    flex: 0 0 73%;
    margin-top: 11px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
    display: flex;
    align-items: center;
    margin-inline-start: 0px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button {
    max-width: 70px;
    height: 100%;
    display: block;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button img {
    width: 100%;
    height: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    justify-content: space-between;
    margin-top: 25px;
    flex: 0 0 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    flex: 0 0 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
    line-height: 1.5rem;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 14px;
    margin-block: 4px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button {
    max-width: 260px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 14px;
  }
  .slideBoxMain .slideMainCir .slideMainCirInner::before {
    height: 60vw;
    transform: rotate(-33deg);
  }
  .slideBoxMain .slideMainCir .slideMainCirInner::after {
    height: 60vw;
    transform: rotate(33deg);
  }
  .nfts .nfts-container .nfts-outer-button-border {
    left: 0px;
    flex: 0 0 100%;
    margin: 0 auto;
    order: 1;
  }
  .nfts .nfts-container .search-box-content {
    flex: 0 0 100%;
    max-width: 100%;
    order: 0;
  }
  .nfts .nfts-container .search-box-content .search-bar-box {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .nftsHeader {
    top: -70px;
  }
  .gridlistview .farming-container .search-box-content {
    flex: 0 0 calc(100% - 145px);
    max-width: calc(100% - 145px);
    order: 0;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    justify-content: space-between;
    margin-top: 30px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .view-btn-group {
    width: 100px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border {
    width: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
  .gridlistview .farming-container .farming-button-area {
    background-image: linear-gradient(#000000 94%, rgba(255, 0, 0, 0));
  }
  .connectBtnMain.poolsBoxHead h2 {
    font-size: 16px;
  }
  .poolsBox {
    padding: 25px 20px;
  }
  .nfts .nfts-container button {
    max-width: 220px;
    margin-left: 10px;
  }
  .nfts .nfts-container button span {
    font-size: 14px;
  }
  .poolsBox .poolsBoxInner {
    padding: 15px;
  }
  .gridlistview .farming-container .farming-content-area {
    margin: 20px auto 0px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1:nth-last-child(1) {
    margin-bottom: 0px;
  }
  .yourPositionBox .headTitle {
    padding: 15px 10px;
  }
  .yourPositionBox .rowBoxItems {
    padding: 15px 10px;
  }
  .yourPositionBox .topheadTtl img {
    width: 22px;
    height: 22px;
  }
  .yourPositionBox .topheadTtl strong {
    font-size: 14px;
  }
  .yourPositionBox .topheadTtl span {
    font-size: 12px;
  }
  .yourPositionBox p {
    font-size: 12px;
    margin-left: -5px;
  }
}
@media (max-width: 567px) {
  .dashboard .abc {
    max-height: 50px !important;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartBox {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData img {
    min-height: 90px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData span {
    right: 0px;
    font-size: 13px;
    top: 50px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData {
    width: 322px;
    position: relative;
    flex: 0 0 100%;
    max-width: 90%;
    display: none;
    height: 133px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData img {
    width: 100%;
    height: 100%;
    position: relative;
    left: -160px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData span {
    display: none;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progrfour {
    flex: 0 0 100%;
    max-width: 90%;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progressCirclebox {
    margin-bottom: 6px;
    padding: 0px 5px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progressCirclebox h3 {
    font-size: 13px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline {
    left: 21%;
    top: 46;
    margin-top: 100px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline::after {
    font-size: 14px;
    left: 27px;
  }
  .dashboard .oc_row .circleBox {
    margin-top: -2vw;
  }
  .dashboard .oc_row .circleBox .infobox {
    margin-block: 2.5vw;
  }
  .dashboard .oc_row .circleBox .infobox .Typewriter {
    font-size: 4.2vw;
  }
  .dashboard .oc_row .oc_mobile {
    margin-top: 7.5vw;
  }
  .dashboard .oc_row .oc_mobile .innerbox {
    max-height: 91px;
    min-height: 96px;
  }
  .dashboard .oc_row .boxBottomLeft {
    max-width: 250px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox {
    width: 100%;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr {
    margin-bottom: 15px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr p {
    font-size: 12px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr .progress {
    height: 18px;
  }
  .dashboard .oc_row .boxBottomRight {
    max-width: 250px;
    width: 100%;
  }
  .dashboard .oc_row .boxBottomRight .border_line {
    width: 100%;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox {
    width: 100%;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr {
    margin-bottom: 15px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr p {
    font-size: 11px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr .progress {
    height: 18px;
  }
  .swap .swap-container .button-1 {
    max-width: 183px;
  }
  .swap .swap-container .button-1 span {
    font-size: 14px;
  }
  .swap .swap-container .button-1:hover {
    box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 6px 3px #00d7c3;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 {
    max-width: 400px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .main-box {
    width: 300px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 label {
    margin-bottom: 20px;
    font-size: 17px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 input[type=text] {
    font-size: 17px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .token-balance .token-currency .bg-logo {
    width: 23px;
    height: 23px;
    margin-inline-end: 12px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-2 {
    max-width: 55px;
    margin: 0 auto;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 {
    width: 100%;
    max-width: 400px;
    margin-inline: auto;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .main-box {
    width: 300px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 label {
    margin-bottom: 20px;
    font-size: 17px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 input[type=text] {
    font-size: 17px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .token-balance .token-currency .bg-logo {
    width: 23px;
    height: 23px;
    margin-inline-end: 12px;
  }
  .nfts .nfts-container .nfts-button .button-1 {
    max-width: 183px;
    margin-inline: auto;
  }
  .nfts .nfts-container .nfts-button .button-1 span {
    font-size: 14px;
  }
  .nfts .nfts-container .nfts-button .button-1:hover {
    box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 6px 3px #00d7c3;
  }
  .nfts .nfts-container .search-box-content .search-bar label input[type=search] {
    font-size: 15px;
  }
  .nfts .nfts-container .search-box-content .search-bar label::after {
    top: -23px;
  }
  .nfts .nfts-container .nfts-outer-button-border .nfts-inner-button-border .nfts-button-grp ul li {
    padding: 10px 10px;
    font-size: 12px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box {
    margin: 0 23px 0 20px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-1 {
    font-size: 22px;
  }
  .nfts img.swap_frame {
    left: 47%;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::after {
    left: -4px;
    width: 55px;
    height: 135px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    font-size: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 10px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 15px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    margin-top: 25px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 17px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 16px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    margin-top: 25px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 19px;
    margin-block: 7px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 {
    margin-block: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
    max-width: 210px;
  }
  .gridlistview .farming-container .search-box-content .search-bar-box .search-bar label input[type=search] {
    font-size: 15px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button {
    display: block;
    width: 100%;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 {
    max-width: 183px;
    margin-inline: auto;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 span {
    font-size: 14px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1:hover {
    box-shadow: 0 0 5px 0 #00d7c3 inset, 0 0 6px 3px #00d7c3;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 10px;
    font-size: 14px;
    text-align: center;
  }
  .gridlistview .farming-container .farming-button-area .empty-area {
    width: 0px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 10px;
    font-size: 13px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
    padding: 3px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
    font-size: 13px;
    padding: 8px 10px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll {
    padding: 0px;
    margin: 10px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    max-height: 75px;
    width: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::before {
    width: 36px;
    height: 57px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::after {
    width: 42px;
    height: 90px;
    left: -7px;
    top: -5px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    flex: 0 0 10%;
    font-size: 14px;
    padding: 12px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 .farming-logo-box svg {
    height: 100%;
    max-width: 30px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 0px;
    flex: 0 0 68%;
    align-items: center;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
    flex: 0 0 73%;
    margin-top: 11px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 13px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 13px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 p img {
    display: none;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
    display: flex;
    align-items: center;
    margin-inline-start: 0px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button {
    max-width: 57px;
    height: 100%;
    display: block;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button img {
    width: 100%;
    height: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    justify-content: space-between;
    margin-top: 25px;
    flex: 0 0 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    flex: 0 0 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
    line-height: 1.5rem;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 14px;
    margin-block: 4px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button {
    max-width: 260px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 14px;
  }
  .boxTopLeft .chartBox::before,
  .boxTopLeft .chartBox::after {
    width: 25px;
  }
  .gridlistview:not(.listviewmode) .farming-container .farming-content-area .farming-box-1 {
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
  .gridlistview .farming-container .scroll-border {
    margin: 0px auto;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 {
    flex-basis: calc(100% - 0px);
    max-width: calc(100% - 0px);
    margin: 0px 0px 20px;
  }
  .slideBoxMain .slideMainPages {
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
  .slideBoxMain .slideMainCir {
    flex-basis: calc(100% - 19px);
    max-width: calc(100% - 19px);
  }
  .nfts .nfts-container .nfts-outer-button-border {
    padding: 2px 5px;
  }
  .modal.previewModalBox .topheadTtl strong {
    font-size: 16px;
  }
  .modal.previewModalBox .feeTierTable {
    padding: 15px;
  }
  .modal.previewModalBox .feeTierTable .rowBx:nth-last-child(1) strong {
    font-size: 18px;
  }
  .modal.previewModalBox .selectedRangeBox ul h2 {
    font-size: 16px;
  }
  .modal.previewModalBox .selectedRangeBox ul .tabmenu li {
    font-size: 14px;
  }
  .yourPositionBox p {
    text-align: center;
    margin-top: 20px;
  }
  .yourPositionBox p svg {
    width: 22px;
    display: block;
    margin: 6px auto;
    transform: rotate(90deg);
  }
  .yourPositionBox .headTitle {
    flex-wrap: wrap;
  }
  .yourPositionBox .headTitle h2 {
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .returnSwapbox h2 {
    font-size: 20px;
  }
  .returnSwapbox p {
    font-size: 12px;
  }
  .stakeModalPopup .tokenDiv {
    font-size: 14px !important;
  }
  .stakeModalPopup .form-group.flex {
    justify-content: center;
  }
  .stakeModalPopup .form-group.flex label {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .stakeModalPopup .token-balance {
    text-align: center;
    justify-content: center;
    flex-direction: row !important;
  }
}
@media (max-width: 500px) {
  .tokenDiv {
    font-size: 11px !important;
  }
  .swap .swap-container .swap-main-box .token-box-1 .form-group input[type=number] {
    font-size: 16px !important;
  }
  .swap .swap-container .swap-main-box .token-box-3 .form-group input[type=number] {
    font-size: 16px !important;
  }
  .dashboard .abc {
    max-height: 40px !important;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartBox p {
    font-size: 3.5vw;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData img {
    min-height: 50px;
  }
  .dashboard .oc_row .oc_col6 .boxTopLeft .chartData span {
    right: 0px;
    font-size: 9px;
    top: unset;
    bottom: 10px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .chartData {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progrfour {
    flex: 0 0 100%;
    max-width: 93%;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progressCirclebox {
    margin-bottom: 6px;
    padding: 0px 3px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progressCirclebox h3 {
    font-size: 9px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .oc_row .progressCirclebox span {
    font-size: 10px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline {
    left: 50%;
    top: 20vw;
    margin-top: 100px;
  }
  .dashboard .oc_row .oc_col6 .boxTopRight .boxline::after {
    font-size: 12px;
    left: 14px;
  }
  .dashboard .oc_row .circleBox {
    min-width: 270px;
    min-height: 270px;
    max-height: 280px;
  }
  .dashboard .oc_row .circleBox .centerlogo {
    min-width: 90px;
    min-height: 90px;
  }
  .dashboard .oc_row .circleBox .infobox {
    margin-block: 2.5vw;
  }
  .dashboard .oc_row .circleBox .infobox .Typewriter {
    font-size: 4.2vw;
  }
  .dashboard .oc_row .oc_mobile {
    margin-top: 8.5vw;
  }
  .dashboard .oc_row .oc_mobile .innerbox {
    max-height: 91px;
    min-height: 96px;
    padding-inline: 5px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr {
    margin-bottom: 12px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr p {
    font-size: 11px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr .progress {
    height: 16px;
  }
  .dashboard .oc_row .boxBottomLeft .progressbox .progressboxinr .progress span {
    font-size: 14px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr {
    margin-bottom: 12px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr p {
    font-size: 11px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr .progress {
    height: 16px;
  }
  .dashboard .oc_row .boxBottomRight .border_line .progressbox .progressboxinr .progress span {
    font-size: 14px;
  }
  .swap .swap-container .button-1 span {
    font-size: 14px;
  }
  .swap .swap-container .swap-main-box .input-boxes::after {
    bottom: 3px;
    max-width: 3px;
    right: -1px;
  }
  .swap .swap-container .swap-main-box .input-boxes::before {
    bottom: 1px;
    max-width: 3px;
    left: -3px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 {
    max-width: 100%;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .main-box {
    max-width: calc(100% - 40px);
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .main-box label {
    margin-bottom: 9px;
    font-size: 14px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .main-box input[type=text] {
    font-size: 14px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .token-balance .token-currency .bg-logo {
    width: 19px;
    height: 19px;
    margin-inline-end: 10px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-1 .token-balance .token-currency span {
    font-size: 12px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-2 {
    max-width: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-2 svg {
    width: 70px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 {
    max-width: 100%;
    margin-inline: auto;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .main-box {
    max-width: calc(100% - 40px);
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 label {
    margin-bottom: 9px;
    font-size: 14px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 input[type=text] {
    font-size: 14px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .token-balance .token-currency .bg-logo {
    width: 19px;
    height: 19px;
    margin-inline-end: 10px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-box-3 .token-balance .token-currency span {
    font-size: 12px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button {
    margin-top: 20px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button span {
    font-size: 14px;
  }
  .swap .swap-container .swap-main-box .input-boxes .token-button .bg-button {
    max-width: 250px;
  }
  .swap .swap-container .button-1 span {
    font-size: 11px;
  }
  .swap img.swap_frame {
    top: 30px;
    max-width: 547px;
  }
  .nfts .nfts-container .scroll::-webkit-scrollbar {
    width: 3px;
  }
  .nfts .nfts-container .nfts-button .button-1 {
    max-width: 140px;
    margin-inline: auto;
  }
  .nfts .nfts-container .nfts-button .button-1 span {
    font-size: 11px;
  }
  .nfts .nfts-container .search-box-content .search-bar label input[type=search] {
    font-size: 15px;
  }
  .nfts .nfts-container .search-box-content .search-bar label::after {
    top: -23px;
  }
  .nfts .nfts-container .scroll-border::after {
    rotate: 221deg;
  }
  .nfts .nfts-container .scroll-border::before {
    rotate: 142deg;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-sub-input-box {
    height: 60px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box {
    margin: 0 10px 0 8px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-1 {
    width: 40px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-1 {
    font-size: 18px;
  }
  .nfts .nfts-container .nfts-content-area .nfts-box-1 .nfts-border-1 .nfts-input-box .nfts-volume-box .volume-box-2 .span-2 {
    font-size: 13px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    width: 260px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::before {
    width: 36px;
    height: 57px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::after {
    width: 42px;
    height: 90px;
    left: -7px;
    top: -5px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    font-size: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 5px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    margin-top: 15px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    margin-top: 15px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 16px;
    margin-block: 5px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 {
    margin-block: 20px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 12px;
  }
  .gridlistview .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button .bg-button {
    max-width: 190px;
  }
  .gridlistview .farming-container .search-box-content .nfts-button {
    display: block;
    width: 100%;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 {
    max-width: 140px;
    margin-inline: auto;
  }
  .gridlistview .farming-container .search-box-content .nfts-button .button-1 span {
    font-size: 11px;
  }
  .gridlistview .farming-container .farming-button-area .flex-btn-group .farming-outer-button-border .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 10px;
    font-size: 12px;
    text-align: center;
  }
  .gridlistview .farming-container .farming-button-area .empty-area {
    width: 0px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li {
    padding: 10px 10px;
    font-size: 12px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp ul li .dropdown-item {
    padding: 3px;
  }
  .gridlistview .farming-container .farming-button-area .farming-outer-button-border-1 .farming-inner-button-border .farming-button-grp button {
    font-size: 12px;
    padding: 8px 10px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll {
    padding: 0px;
    margin: 10px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 {
    max-height: 66px;
    width: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::before {
    width: 36px;
    height: 57px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1::after {
    width: 42px;
    height: 90px;
    left: -7px;
    top: -5px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 {
    flex: 0 0 50%;
    font-size: 15px;
    padding: 0;
    margin-right: auto;
    margin-top: 15px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 .farming-logo-box {
    margin-right: 11px;
    margin-left: 3px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-1 .farming-logo-box svg {
    height: 100%;
    max-width: 30px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 {
    padding-inline: 0px;
    flex: 0 0 90%;
    align-items: center;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 {
    flex: 0 0 100%;
    margin-top: 11px;
    order: 2;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-1 {
    font-size: 13px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 {
    font-size: 13px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-1 .span-area-2 p img {
    display: none;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button {
    display: flex;
    align-items: center;
    margin-inline-start: 0px;
    flex: 0 0 100%;
    margin-top: -26px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button {
    max-width: 57px;
    height: 100%;
    display: block;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-button button img {
    width: 100%;
    height: 100%;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 {
    justify-content: space-between;
    margin-top: 25px;
    flex: 0 0 100%;
    order: 3;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-3 p .span-number {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-2 .span-area-4 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 {
    flex: 0 0 100%;
    order: 4;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p {
    font-size: 12px;
    line-height: 1.5rem;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-5 p .span-letter {
    font-size: 14px;
    margin-block: 4px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 {
    height: auto;
    max-width: initial;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 button {
    font-size: 14px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-2 .frame-input-sub-3 .span-area-6 .harvest-bg {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button {
    max-width: 260px;
  }
  .gridlistview.listviewmode .farming-container .scroll-border .scroll .farming-content-area .farming-box-1 .frame-sub-box-3 button span {
    font-size: 14px;
  }
  .middelBox .oc_col12 {
    margin-top: 35px;
  }
  .swap .swap-container .swap-main-box .swapNetwork .Collapsible__trigger {
    padding-left: 28px;
    font-size: 14px;
  }
  .swap .swap-container .swap-main-box .swapNetwork .infoIcon {
    top: -2px;
    width: 20px;
  }
  .swap .swap-container .swap-main-box .swapNetwork .topBtnArrow {
    width: 18px;
  }
  .swap .swap-container .swap-main-box .swapNetwork .Collapsible__contentOuter .Collapsible__contentInner p span {
    font-size: 12px;
  }
  .swap .swap-container .swap-main-box .swapNetwork .Collapsible__contentOuter .Collapsible__contentInner p span {
    font-size: 12px;
  }
  .nftLiquidity .topheadTtl h2 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .nftLiquidity .topheadTtl .btnGrp {
    flex-wrap: wrap;
    justify-content: center;
  }
  .nftLiquidity .topheadTtl .btnGrp button {
    margin-top: 10px;
  }
  .nftLiquidity .liquidityTable .rowBoxTbl {
    flex-wrap: wrap;
    justify-content: center;
  }
  .nftLiquidity .liquidityTable .rowBoxTbl .imgBx {
    margin: 0px 0px 6px;
  }
  .nftLiquidity .liquidityBox h2 {
    font-size: 26px;
  }
  .removeLiqBx .amountBox .countBox ul {
    flex-wrap: wrap;
    margin-left: 10px;
  }
  .removeLiqBx .amountBox .countBox ul li {
    padding: 4px 6px;
    font-size: 14px;
    flex: auto;
    margin: 0px 5px 5px;
    text-align: center;
    max-width: calc(50% - 10px);
  }
}
@media (max-width: 400px) {
  .slideBoxMain .slideMainCir .slideMainCirInner .slideSwapCirBack {
    max-width: 320px;
  }
  .slideBoxMain .slideMainCir .slideMainCirInner {
    height: 220px;
  }
  .slideBoxMain .slideMainCir {
    height: 220px;
  }
  .slideBoxMain .slideMainCir svg {
    max-width: 145px;
    top: calc(50% + 91px);
    left: calc(50% - 8px);
  }
  .slideBoxMain .slideMainCir .slideMainCirInner .swapLogo {
    top: calc(50% + 89px);
    left: calc(50% - 7px);
    width: 50px;
  }
}
/* container-media */
@media (min-width: 992px) {
  .oc_row {
    display: flex;
  }
  .oc_row .oc_mobile {
    display: none;
  }
  .hiddenDesk {
    display: none !important;
  }
  .modal.newPositionMdl .popMaininrBox .row .col-lg-6:nth-child(1) {
    order: 0;
  }
  .modal.newPositionMdl .popMaininrBox .row .col-lg-6:nth-child(2) {
    order: 1;
  }
  .modal.newPositionMdl .popMaininrBox .row .col-lg-6:nth-child(3) {
    order: 3;
  }
  .modal.newPositionMdl .popMaininrBox .row .col-lg-6:nth-child(4) {
    order: 2;
  }
  .modal.newPositionMdl .popMaininrBox .row .col-lg-6:nth-child(5) {
    order: 4;
  }
  .modal.newPositionMdl .popMaininrBox .row .col-lg-6:nth-child(6) {
    order: 5;
  }
}
.tokenDiv {
  font-size: 22px;
}

.lottery-cupan button {
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 8px 5px 15px 0px #000;
}

.lottery-cupan {
  animation: 7s ease-in-out 0s infinite normal none running rotate;
}

@keyframes rotate {
  0%, 100% {
    transform: rotate(-4deg);
  }
  25% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(4deg);
  }
}
.ticketsBox {
  position: relative;
}
.ticketsBox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-image: url("../images/poolbox/poolboxtopline.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  z-index: -1;
}
.ticketsBox::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-image: url("../images/poolbox/poolboxbottomline.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;
  z-index: -1;
}

.border-\[\#383241\] {
  border-color: #383241 !important;
}

/* media end *//*# sourceMappingURL=style.css.map */